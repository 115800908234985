import React, { useEffect } from "react";
import Migration from "../assets/cloud_migration.png"
import Portfolio from "../assets/portfolio_optimization.png"
import Strategy from "../assets/strategy.png"
import Equipment from "../assets/equipment.png"
import Development from "../assets/coding.png"
import Assesment from "../assets/assesment.png"
import ITConsultancyWeb from "../components/web/ITConsultancyWeb";
import ITConsultancyMobile from "../components/mobile/ITConsultancyMobile";


const ITConsultancy = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const benefits = [
        {
            "title": "Benefits of IT consultancy services for business",
            'text': 'Reduces IT costs',
            'icon': 'trending-down-outline'
        },
        {
            'text': 'Identifying Improvement Areas',
            'icon': 'search-circle-outline'
        },
        {
            'text': 'External Operational Evaluation',
            'icon': 'speedometer-outline'
        },
        {
            'text': ' Specialized Advice and Attention ',
            'icon': 'extension-puzzle-outline'
        },
    ]

    const applications = [
        {
            'text': 'Cloud migration',
            'image': Migration,
        },
        {
            'text': 'Software portfolio optimization',
            'image': Portfolio
        },
        {
            'text': 'IT strategy planning',
            'image': Strategy
        },
        {
            'text': 'Hardware and software acquisition',
            'image': Equipment
        },
        {
            'text': 'IT assessments',
            'image': Assesment
        },
        {
            'text': 'Software development',
            'image': Development
        },
    ]

    return (
        <div className="w-full">
            <div className="hidden sm:block">
                <ITConsultancyWeb service={props.service} benefits={benefits} applications={applications} />
            </div>
            <div className="sm:hidden">
                <ITConsultancyMobile service={props.service} benefits={benefits} applications={applications} />
            </div>
        </div>
    );
}

export default ITConsultancy;