import React from "react";
import BackendBanner from "../../assets/backend_banner.png";
import { FloatingButton, WhatsAppWidget } from "../general";
import BenefitsMobile from "./BenefitsMobile";
import ServiceTypeMobile from "./ServiceTypeMobile";
import TechnologiesMobile from "./TechnologiesMobile";
import TopBannerMobile from "./TopBannerMobile";

const BackendAndApiMobile = (props) => {
  return (
    <div className="w-full mb-5">
      <TopBannerMobile
        img={BackendBanner}
        title="Backend and API intergration<br /> services"
        caption="Choose the right backend strategy"
      />
      <div className="flex flex-col justify-center items-center mt-8">
        <p className="text-ss text-center text-blue mt-[3vh]">
          A development company that knows your needs
        </p>

        <p className="text-sm font-light p-4">
          Our clients can design and construct custom software solutions that
          easily connect and integrate various systems thanks to our expertise
          in API development and integration. Our team of professionals can help
          you establish a networked ecosystem that facilitates data sharing and
          communication across your systems, increasing automation and overall
          efficiency. Our expertise lies in developing scalable and resilient
          API systems that can manage substantial volumes of data and traffic,
          guaranteeing a seamless implementation and uptake process for your
          company. To guarantee a flawless API integration, we also offer API
          creation, testing, and maintenance services.
        </p>

        <div className="bg-[#F8F9F9] w-full mt-5">
          <div className="my-[4vh]">
            <div className="flex justify-center items-center">
              <p className="text-blue text-ss mb-4">
                Benefits of APIs for business
              </p>
            </div>
            <BenefitsMobile benefits={props.benefits} />
          </div>
        </div>

        <div>
          <div className="flex justify-center items-center mt-5 mx-3">
            <p className="text-blue text-ss m-5 text-center">
              Types of backends and APIs we develop and integrate
            </p>
          </div>
          <ServiceTypeMobile applications={props.applications} />
        </div>

        <div className="bg-[#F8F9F9] w-full mt-[5vh]">
          <div className="flex flex-col justify-center items-center mt-[2vh]">
            <p className="text-blue text-ss text-center  mt-4">
              Technologies we use
            </p>
            <p className="text-sm m-4  font-light">
              We have the ability to work with the following frameworks and
              technologies, depending on your project requirements:
            </p>
          </div>
          <TechnologiesMobile technologies={props.technologies} />
        </div>
      </div>
      <FloatingButton />
      <WhatsAppWidget />
    </div>
  );
};

export default BackendAndApiMobile;
