import React from "react";
import WebBanner from "../../assets/web_banner.png";
import FloatingButton from "../general/FloatingButton";
import WhatsAppWidget from "../general/WhatsApp";
import BenefitsWeb from "./BenefitsWeb";
import ServiceTypeWeb from "./ServiceTypeWeb";
import TechnologiesWeb from "./TechnologiesWeb";
import TopBannerWeb from "./TopBannerWeb";

const WebAppDevWeb = (props) => {
  return (
    <div>
      <TopBannerWeb
        img={WebBanner}
        title="Web application development<br /> services"
        caption="Develop a web application that leverages innovation"
      />
      <div className="flex flex-col justify-center items-center mt-8">
        <p className="text-3xl xl:text-5xl mt-[8vh] text-blue">
          Hire business-oriented web developers
        </p>
        <p className="text-xl mx-10 lg:mx-[15vh] mt-8  font-light">
          Our skilled team of web developers specializes in building unique web
          apps that are customized to match the specific requirements of our
          customers. We approach development with a user-centered mindset,
          making sure the finished product is user-friendly, intuitive, and
          satisfies our clients' unique needs. We collaborate closely with our
          clients to provide web-based solutions that are dependable, scalable,
          and successful in helping them achieve their business objectives, from
          the first concept to the last deployment.
        </p>
        <BenefitsWeb benefits={props.benefits} />

        <div>
          <div className="flex justify-center items-center">
            <p className="text-blue text-3xl xl:text-5xl mt-[8vh] mb-5">
              Types of web applications we develop
            </p>
          </div>
          <ServiceTypeWeb applications={props.applications} />
        </div>

        <div className="bg-[#F8F9F9] w-full mt-[5vh]">
          <div className="flex flex-col justify-center items-center mt-[6vh]">
            <p className="text-blue text-3xl xl:text-5xl text-center  mt-4">
              Technologies we use
            </p>
            <p className="text-2xl mx-5 lg:mx-[20vh] m-4 text-center  font-light">
              We have the ability to work with the following frameworks and
              technologies, depending on your project requirements:
            </p>
          </div>
          <TechnologiesWeb technologies={props.technologies} />
        </div>
      </div>
      <FloatingButton />
      <WhatsAppWidget />
    </div>
  );
};

export default WebAppDevWeb;
