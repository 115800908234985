import React, { useEffect } from "react";
import Education from "../assets/education.png"
import SocialMedia from "../assets/social_media.png"
import MobileCom from "../assets/mobile_comm.png"
import Ticketing from "../assets/event_booking.png"
import MentalHealth from "../assets/mental_health.png"
import Fintech from "../assets/fintech.png"
import Flutter from "../assets/flutter.svg"
import Kotlin from "../assets/kotlin.svg"
import Java from "../assets/java.svg"
import ReactNative from "../assets/react.svg"
import MobileAppDevWeb from "../components/web/MobileAppDevWeb";
import MobileAppDevMobile from "../components/mobile/MobileAppDevMobile";


const MobileAppDev = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const benefits = [
        {
            "title": "Benefits of mobile & tablet applications for business",
            'text': 'Give your clients greater value',
            'icon':'trending-up-outline'
        },
        {
            'text': 'Build brand awareness',
            'icon':'newspaper-outline'
        },
        {
            'text': 'Reduction In Operational Cost',
            'icon':'trending-down-outline'
        },
        {
            'text': 'Improve customer engagement',
            'icon':'people-outline'
        },
    ]

    const applications = [
        {
            'text': 'Educational Apps',
            'image': Education
        },
        {
            'text': 'Social Media Apps',
            'image': SocialMedia
        },
        {
            'text': 'Mobile Commerce Apps',
            'image': MobileCom
        },
        {
            'text': 'Booking & Ticketing Apps',
            'image': Ticketing
        },
        {
            'text': 'Mental Health & Wellness Apps',
            'image': MentalHealth
        },
        {
            'text': 'Fintech Apps',
            'image': Fintech
        },
    ]

    const technologies = [
        {
            'image': Flutter
        },
        {
            'image': Java
        },
        {
            'image': Kotlin
        },
        {
            'image': ReactNative
        }
    ]

    return (
        <div className="w-full">
            <div className="hidden sm:block">
                <MobileAppDevWeb service={props.service} benefits={benefits} applications={applications} technologies={technologies} />
            </div>
            <div className="sm:hidden">
                <MobileAppDevMobile service={props.service} benefits={benefits} applications={applications} technologies={technologies} />
            </div>
        </div>
    );
}

export default MobileAppDev;