import React from "react";
import arrow from "../../assets/arrowd.gif";

const Process = () => {
  return (
    <div className="bg-white py-16 mt-1">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-7xl sm:text-center mb-14">
          <p className="mt-2 text-3xl font-bold tracking-tight text-blue sm:text-4xl">
            Our Process
          </p>
          <p className="mt-2 text-lg sm:text-center leading-8 text-gray-800">
            We believe in a collaborative approach to software development,
            where we work closely with our clients to understand their unique
            challenges and goals. By leveraging the latest technologies and best
            practices, we develop custom solutions that address our clients'
            specific needs and drive tangible results.
          </p>
        </div>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
          <div>
            <div className="flex items-center text-sm font-semibold leading-6 text-green">
              <svg
                viewBox="0 0 4 4"
                className="mr-4 h-1 w-1 flex-none"
                aria-hidden="true"
              >
                <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
              </svg>
              Step 1
              <div
                className="absolute -ml-2 h-px w-screen -translate-x-full bg-black sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                aria-hidden="true"
              ></div>{" "}
              <div>
                <img src={arrow} alt="" className="ml-1" />
              </div>
            </div>
            <p className="mt-6 text-lg font-semibold leading-8 tracking-wide text-blue">
              Project Initiation:
            </p>
            <p className="mt-1 text-base leading-7 text-black">
              Through close collaboration, we start by understanding your
              business needs, goals, and target audience
            </p>
          </div>
          <div>
            <div className="flex items-center text-sm font-semibold leading-6 text-green">
              <svg
                viewBox="0 0 4 4"
                className="mr-4 h-1 w-1 flex-none"
                aria-hidden="true"
              >
                <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
              </svg>
              Step 2
              <div
                className="absolute -ml-2 h-px w-screen -translate-x-full bg-black sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                aria-hidden="true"
              ></div>{" "}
              <div>
                <img src={arrow} alt="" className="ml-1" />
              </div>
            </div>
            <p className="mt-6 text-lg font-semibold leading-8 tracking-wide text-blue">
              Design & Development:
            </p>
            <p className="mt-1 text-base leading-7 text-black">
              We design you a customized software solution based on your
              requirements at high levels.
            </p>
          </div>
          <div>
            <div className="flex items-center text-sm font-semibold leading-6 text-green">
              <svg
                viewBox="0 0 4 4"
                className="mr-4 h-1 w-1 flex-none"
                aria-hidden="true"
              >
                <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
              </svg>
              Step 3
              <div
                className="absolute -ml-2 h-px w-screen -translate-x-full bg-black sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                aria-hidden="true"
              ></div>{" "}
              <div>
                <img src={arrow} alt="" className="ml-1" />
              </div>
            </div>
            <p className="mt-6 text-lg font-semibold leading-8 tracking-wide text-blue">
              Testing & QA:
            </p>
            <p className="mt-1 text-base leading-7 text-black">
              With close communnication and collaboration with our clients, we
              conduct comprehensive testing to ensure functionality,
              reliability, and security.
            </p>
          </div>
          <div>
            <div className="flex items-center text-sm font-semibold leading-6 text-green">
              <svg
                viewBox="0 0 4 4"
                className="mr-4 h-1 w-1 flex-none"
                aria-hidden="true"
              >
                <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
              </svg>
              Step 4
              {/* <div
                className="absolute -ml-2 h-px w-screen -translate-x-full bg-black sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                aria-hidden="true"
              ></div> */}
            </div>
            <p className="mt-6 text-lg font-semibold leading-8 tracking-wide text-blue">
              Launch and Support:
            </p>
            <p className="mt-1 text-base leading-7 text-black">
              Deployment of product and Provision of ongoing support and
              maintenance to ensure smooth operation and address any issues that
              arise.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
