import React from "react";
import { Link } from "react-router-dom";
import slugify from "slugify";
import ServicesBanner from "../../assets/services_banner.png";
import { FloatingButton, WhatsAppWidget } from "../general";
import ContactBanner from "../general/ContactBanner";
import TopBannerWeb from "./TopBannerWeb";

const ServicesWeb = (props) => {
  return (
    <div className="w-full mb-5">
      <TopBannerWeb
        img={ServicesBanner}
        title="Software Development Services<br /> From Concept To Completion"
        caption="Work with us and transfrom your dream into reality"
      />
      <div className="lg:mx-[15vh] mt-10">
        <div className="grid grid-cols-2 lg:grid-cols-3  m-5 ">
          {props.services.map((service, index) => (
            <Link
              key={service.title}
              to={"/services/" + slugify(service.title.toLocaleLowerCase())}
            >
              <div className="flex flex-col justify-start items-start  hover:shadow-2xl p-6">
                <div>{service.image}</div>
                <div>
                  <p className="text-xl font-semibold">{service.title}</p>
                </div>
                <div>
                  <p className="text-sm mt-3">{service.content}</p>
                  {/* <IonIcon className="text-2xl text-blue hover:text-green" name='arrow-forward-circle-sharp' /> */}
                </div>
              </div>
            </Link>
          ))}
        </div>

        <ContactBanner />
        <FloatingButton />
        <WhatsAppWidget />
      </div>
    </div>
  );
};

export default ServicesWeb;
