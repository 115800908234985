import React, { useState, useEffect } from "react";
import IonIcon from "@reacticons/ionicons";

const FloatingButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY; // Get current scroll position
      setIsVisible(scrollY > window.innerHeight); // Show button after hero section height
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll); // Cleanup on unmount
  }, []); // Empty dependency array for effect to run once

  const backToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      className={`fixed bottom-20 md:right-6 right-4 p-4 ${
        isVisible ? "" : "hidden"
      }`} // Toggle visibility
    >
      <button
        className="bg-green text-white rounded-full w-10 h-10 flex items-center justify-center"
        onClick={backToTop}
      >
        <IonIcon className="text-2xl" name="chevron-up" />
      </button>
    </div>
  );
};

export default FloatingButton;
