import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import TimeAgo from 'react-timeago';
import slugify from "slugify";
import FloatingButton from "../general/FloatingButton";



const BlogMobile = (props) => {
  let [likedNow, setLikedState] = useState(false);
  let [blogs, setBlogs] = useState([]);
  const [seed, setSeed] = useState(1);

  useEffect(() => {
    setBlogs(props.currentItems)
  })


  const getUrl = () => {
    const mode = process.env.REACT_APP_MODE;
    if (mode === 'prod') {
      return process.env.REACT_APP_PROD_BACKEND;
    } else {
      return process.env.REACT_APP_DEV_BACKEND;
    }
  }

  const getComments = (comments) => {
    let approvedComments = []
    comments.map((item) => {
      if (item.approved == true) {
        approvedComments.push(item.id)
      }
    })
    return approvedComments.length
  }

  const likeBlog = (id) => {
    setLikedState(!likedNow)
    setSeed(Math.random());

    let data = {
      blog: id,
      ip_user_agent: props.ip + "=>" + window.navigator.userAgent
    };
    axios
      .post(`${getUrl()}/blogs-api/likes/`, data)
      .then((response) => {
        console.log(response)


      })

      .catch(function (error) {

      });

  }


  const LikeButton = (props1) => {
    console.log(props1)
    let likesCount = props1.likes.length

    let alreadyLikedByUser = false
    props1.likes.map((like) => {
      let currentIpUserAgent = props.ip + "=>" + window.navigator.userAgent

      if (like.ip_user_agent.trim() == currentIpUserAgent) {
        alreadyLikedByUser = true
      }
    })

    console.log(alreadyLikedByUser)
    return (
      <div>
        {likedNow ? (
          <span className="flex gap-1 items-center text-sm" onClick={() => likeBlog(props1.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="#356bb3" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-sky-400 w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
            </svg>
            {likesCount}
          </span>
        ) : (
          <span className="flex gap-1 items-center text-sm" onClick={() => likeBlog(props1.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-sky-400 w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
            </svg>
            {likesCount}
          </span>
        )}
      </div>
    )
  }

  return (
    <div className=" flex flex-col mt-5">
      <p className="text-2xl font-bold text-center gradient-text">Latest posts</p>
      <div className="flex justify-center items-center">
        <div className="grid grid-cols-1 mt-3">
          {props.currentItems.map((blog, index) => (
            <div className="bg-white mt-6 m-[4vh] w-[42vh] shadow rounded-lg overflow-hidden">
              <Link to={"/blog/" + slugify(blog.headline.toLocaleLowerCase())}
                state={blog}>
                <img src={blog.blog_image} className="object-cover h-52 w-full" alt="" />
              </Link>
              <div className="p-6">
                <span className="block text-slate-400 font-semibold text-sm">Posted
                  <p> <TimeAgo date={blog.date_posted} /></p>
                </span>
                <Link to={"/blog/" + slugify(blog.headline.toLocaleLowerCase())}
                  state={blog}>
                  <h3 className="mt-3 font-bold text-blue sm:text-xs xl:text-xl pb-1 border-b border-slate-300">
                    {blog.headline}</h3>
                </Link>
                <h6 className="mt-3 text-sm pb-4 border-b border-slate-300">
                  {blog.subheadline}</h6>
                <div className="flex mt-4 gap-4 items-center">
                  <span className="flex gap-1 items-center text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                    35
                  </span>
                  <LikeButton key={seed} id={blog.id} likes={blog.likes} />
                  <span className="flex gap-1 items-center text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 text-lime-500">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                    </svg>
                    {getComments(blog.comments)}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {props.currentItems.length > 6 ? (
        <div className="flex justify-center items-center w-full mb-5">
          <ReactPaginate
            className="flex space-x-3  border border-[#356bb3] p-2"
            breakLabel="..."
            nextLabel="next"
            onPageChange={props.handlePageClick}
            pageRangeDisplayed={5}
            pageCount={props.pageCount}
            previousLabel="previous"
            renderOnZeroPageCount={null}
          />
        </div>
      ) : (
        ""
      )}
      <FloatingButton />
    </div>
  );
};

export default BlogMobile;
