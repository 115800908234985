import SocialIcons from '../general/socialIcons';
import { Link } from "react-router-dom";

const FooterMobile = () => {
    const currentYear = new Date().getFullYear();
    const bgI = process.env.PUBLIC_URL + '/logo.png'
    return (
        <div className="flex flex-col justify-start items-start p-5 bg-[#F3F2F3]">
            <div className='mb-5'>
                <Link to="/">
                    <img className="h-14 md:h-18 lg:h-20 w-auto scale-105" src={process.env.PUBLIC_URL + '/logobg.png'} alt="" />
                </Link>
                <p className='mt-2 text-black-300'>Empowering Tomorrow, Crafting Today.
                </p>
                <SocialIcons />

            </div>

            <div className='mb-5'>
                <h5 className='tracking-wide text-green font-bold'>Quick Links</h5>
                <ul className='list-none m-2 space-y-2'>
                    <li>
                        <Link to="/"
                            className='text-black-300 hover:text-blue transition-all duration-500 ease-in-out'>
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/about-us"
                            className='text-black-300 hover:text-blue transition-all duration-500 ease-in-out'>
                            About Us
                        </Link>
                    </li>
                    <li>
                        <Link to="/services"
                            className='text-black-300 hover:text-blue transition-all duration-500 ease-in-out'>
                            Our Services
                        </Link>
                    </li>
                    <li>
                        <Link to="/blog"
                            className='text-black-300 hover:text-blue transition-all duration-500 ease-in-out'>
                            Blog
                        </Link>
                    </li>
                    <li>
                        <Link to="/portfolio"
                            className='text-black-300 hover:text-blue transition-all duration-500 ease-in-out'>
                            Portfolio
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact-us"
                            className='text-black-300 hover:text-blue transition-all duration-500 ease-in-out'>
                            Contact Us
                        </Link>
                    </li>
                </ul>
            </div>

            <div className='mb-5'>
                <h5 className='tracking-wide text-green font-bold'>Contact Info</h5>
                <ul className='list-none m-2 space-y-2'>
                    <li>
                        <h6
                            className='text-blue font-semibold'>
                            Address:
                        </h6>
                        <p className='mt-2 text-black-600'>1<sup>st</sup> Floor Room 1,Swan Villa. Utawala Road,Nairobi</p>
                    </li>
                    <li>
                        <h6
                            className='text-blue font-semibold'>
                            Email:
                        </h6>
                        <Link to="mailto:info@jasirilabs.tech" className='mt-2 text-black-600 hover:text-green transition-all duration-500 ease-in-out'>info@jasirilabs.tech</Link>
                    </li>
                    <li>
                        <h6
                            className='text-blue font-semibold'>
                            Phone:
                        </h6>
                        <Link to="tel:+254711793446" className='mt-2 text-black-600 hover:text-green transition-all duration-500 ease-in-out'>+254 711 793446</Link>
                    </li>

                </ul>
            </div>

            <div className='mb-5'>
                <h5 className='tracking-wide text-green font-bold'>Newsletter</h5>
                <form action="">
                    <div className='my-3'>
                        <p className='text-sm'>Get the lastest! Subscribe to our newsletter.</p>
                        <input type="email" required className='mt-3 w-[40vh] py-2 px-3 h-10 bg-transparent rounded outline-none border border-gray-500 focus:border-green focus:ring-0' placeholder='Enter your Email' />
                    </div>
                    <button
                        type="submit"
                        className="py-2 px-5 text-white tracking-wider border duration-500 text-base text-center bg-gradient-to-r from-green to-blue hover:from-blue hover:to-green border-blue hover:border-green rounded-md flex justify-center">
                        Subscribe
                    </button>
                </form>
            </div>

            <div>
                <p className='text-center mx-[5vh] font-semibold'>
                    Copyright  &copy; {currentYear} Jasiri Labs. All rights reversed.
                </p>
            </div>
        </div>

    );
};

export default FooterMobile;