import React from "react";
import ContactBanner from "../../assets/contact.jpg";
import { Link } from "react-router-dom";

const ContactBannerMobile = () => (
  <div className="relative bg-gradient-to-r from-blue to-green">
    <img src={ContactBanner} alt="" className="h-[35vh] xxs:h-[40vh] w-full opacity-50" />
    <div className="flex  absolute top-[5vh] xxs:top-[10vh] left-[2vh] inset-0 z-10">
      <div className="flex flex-col  h-fit">
        <p className="text-xs  xxs:text-xl text-white font-semibold">
          Have a project in mind?
          <br /> Let's talk!</p>
        <p className="text-white text-[8px] xxs:text-xs mt-3">Fill out the form or drop us a line via e-mail. We'll get back to you soon!</p>
        <Link to="/contact-us/">
          <div className="rounded-r-full rounded-bl-full text-sm xl:text-xl bg-gradient-to-r from-green to-[#356bb3] text-white p-4 mt-3 w-[20vh]">
            <p className="text-center text-[8px] xxs:text-sm">Get a quote</p>
          </div>
        </Link>

      </div>
    </div>
  </div>
);

export default ContactBannerMobile;