import React from "react";
import TimeAgo from 'react-timeago';
import Comments from "../../components/general/Comments";
import SocialIcons from "../../components/general/socialIcons";
import FloatingButton from "../general/FloatingButton";



const BlogViewMobile = (props) => {
  return (
    <div className="w-full">
      <div>
        <div>
          <p className="flex text-xl font-bold text-center mt-[6vh]">
            {props.blog.headline}
          </p>
          <div className="flex items-center justify-center font-light my-2 space-x-3">
            <p className="capitalize">By {props.blog.author_name}</p>
            <p>
              Posted <TimeAgo date={props.blog.date_posted} />
            </p>
          </div>
          <div className="flex flex-col justify-center items-center mt-4">
            <img src={props.blog.blog_image} alt="" className="w-[40vh]" />
            <div className="flex justify-start items-center space-x-5 mt-2">
              <SocialIcons />
            </div>
            <p
              className="text-sm mx-5 text-justify mt-4"
              dangerouslySetInnerHTML={{ __html: props.blog.blog_content }}
            ></p>
          </div>
        </div>
        <Comments comments={props.blog.comments} id={props.blog.id} />
      </div>
      <FloatingButton />
    </div>
  );
};

export default BlogViewMobile;
