import React from "react";
import {
  django,
  fluttersvg,
  java,
  javaScript,
  jquery,
  laravelSvg,
  php,
  pythonSvg,
  reactsvg,
  stripe,
  twilio,
  vue,
  word,
} from "../../assets";

const Tech = () => {
  return (
    <div className="container p-8 mx-auto w-full max-w-2xl xl:px-0">
      <div className="flex flex-col justify-center">
        <div className="text-4xl text-center text-semibold text-gray-700">
          Our <span className="text-blue font-bold">Tech </span> Stack
        </div>
        <div className="flex flex-wrap justify-center gap-5 mt-10 md:justify-around">
          <div className="pt-4 text-gray-400 hover:scale-110 duration-200 cursor-pointer">
            <img src={pythonSvg} alt="" className="w-16" />
          </div>
          <div className="text-gray-400 hover:scale-110 duration-200 cursor-pointer">
            <img src={reactsvg} alt="" className="w-16" />
          </div>
          <div className="text-gray-400 hover:scale-110 duration-200 cursor-pointer">
            <img src={fluttersvg} alt="" className="w-16" />
          </div>
          <div className="pt-1 text-gray-400 hover:scale-110 duration-200 cursor-pointer">
            <img src={laravelSvg} alt="" className="w-16" />
          </div>
          <div className="pt-4 text-gray-400 hover:scale-110 duration-200 cursor-pointer">
            <img src={django} alt="" className="w-16" />
          </div>
          <div className="pt-4 text-gray-400 hover:scale-110 duration-200 cursor-pointer">
            <img src={javaScript} alt="" className="w-16 " />
          </div>
          <div className="pt-4 text-gray-400 hover:scale-110 duration-200 cursor-pointer">
            <img src={jquery} alt="" className="w-16" />
          </div>
          <div className="pt-4 text-gray-400 hover:scale-110 duration-200 cursor-pointer">
            <img src={java} alt="" className="w-16" />
          </div>
          <div className="pt-4 text-gray-400 hover:scale-110 duration-200 cursor-pointer">
            <img src={twilio} alt="" className="w-16" />
          </div>
          <div className="pt-4 text-gray-400 hover:scale-110 duration-200 cursor-pointer">
            <img src={word} alt="" className="w-16" />
          </div>
          <div className="pt-4 text-gray-400 hover:scale-110 duration-200 cursor-pointer">
            <img src={vue} alt="" className="w-16" />
          </div>
          <div className="pt-4 text-gray-400 hover:scale-110 duration-200 cursor-pointer">
            <img src={php} alt="" className="w-16" />
          </div>
          <div className="pt-4 text-gray-400 hover:scale-110 duration-200 cursor-pointer">
            <img src={stripe} alt="" className="w-16" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tech;
