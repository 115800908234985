import IonIcon from "@reacticons/ionicons";
import React from "react";

const BenefitsMobile = (props) => {
    return (
        <div className="flex flex-wrap justify-center items-center">
            {props.benefits.map((benefit) =>
                <div className="bg-white shadow-2xl rounded-md w-[20vh] h-[15vh] m-3 flex flex-col justify-center items-center">
                    <IonIcon className="text-2xl text-blue hover:text-green" name={benefit.icon} />
                    <p className="text-xs text-center  font-medium p-3 gradient-text">{benefit.text}</p>
                </div>
            )}
        </div>
    );
}

export default BenefitsMobile;