import React, { useEffect } from "react";
import { CTA } from "../components";
import {
  About,
  AboutSec,
  Features,
  FloatingButton,
  Process,
  Team,
  Tech,
  WhatsAppWidget,
} from "../components/general";
import styles from "./style";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full overflow-hidden">
      <About />
      <AboutSec />
      <Features />
      <Tech />
      <Process />
      <Team />
      <div className="w-full overflow-hidden">
        <div className={`bg-white ${styles.paddingX} ${styles.flexStart}`}>
          <div className={`${styles.boxWidth}`}>
            <CTA />
          </div>
        </div>
      </div>
      <FloatingButton />
      <WhatsAppWidget />
    </div>
  );
};

export default AboutUs;
