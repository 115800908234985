import React from "react";
import { Link } from "react-router-dom";
import { clients } from "../../constants";
import styles from "../../pages/style";

let url = window.location.href;
let arr = url.split("/");
let slug = arr[arr.length - 1];

let words = slug.split("-");
let page = "";
words.map((word) => (page = page + " " + word));

const OurClients = () => {
  return (
    <div className="flex flex-col justify-center items-center mt-[10vh]">
      <p
        className={`text-3xl xl:text-5xl font-poppins font-semibold ${
          page.trim() === "portfolio" ? "text-blue" : "text-black-2"
        } `}
      >
        Our clients
      </p>
      <p
        className={` ${
          page.trim() === "portfolio"
            ? `mx-[2vh] text-sm xs:mx-[10vh] xs:text-2xl`
            : `${styles.paragraph}`
        } mt-[2vh] text-center`}
      >
        Embrace the future of technology with confidence, knowing that Jasiri
        Labs is your trusted partner in turning your vision into a reality.
        Elevate your presence and stay ahead of the competition with our expert
        development solutions.
      </p>
      <div className="grid grid-cols-1 xxs:grid-cols-2 xs:grid-cols-3 mt-8 ">
        {clients.map((client) => (
          <div className="flex justify-center items-center m-[2vh] xs:mx-[6vh]  rounded-r-3xl rounded-bl-3xl w-[20vh] h-[20vh]">
            <Link to={client.link} rel="noreferrer" target="_blank">
              <img src={client.image} alt="" className="rounded-2xl" />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurClients;
