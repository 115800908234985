import React from "react";
import contact from "../../assets/contact.jpg";
import { Link } from "react-router-dom";

const ContactBannerWeb = () => (
     <div className="relative bg-gradient-to-r from-blue to-green">
                <img src={contact} alt="" className="h-[35vh] lg:h-[55vh] xl:h-[50vh] w-full opacity-30" />
                <div className="flex  absolute top-[15vh] left-[10vh] lg:left-[20vh] inset-0 z-10">
                    <div className="flex flex-col  h-fit">
                        <p className="text-3xl xl:text-6xl text-white font-semibold">
          Have a project in mind?
          <br /> Let's talk!</p>
          <p className="text-white text-xl xl:text-3xl mt-3 my-2">Fill out the form or drop us a line via e-mail.We'll get back to you soon!</p>
         <Link to="/contact-us/">
                            <div className="rounded-r-full rounded-bl-full text-sm xl:text-xl bg-gradient-to-r from-green to-blue text-white p-4 mt-3 w-fit">
                                <p>Get a quote</p></div>
                        </Link>
      </div>
    </div>
  </div>
);

export default ContactBannerWeb;