import { Option, Select } from "@material-tailwind/react";
import React from "react";

// const options = [
//   { label: "Backend and API Development", value: 1 },
//   { label: "User Interface and UX Design", value: 2 },
//   { label: "Web Application Development", value: 3 },
//   { label: "Mobile Application Development", value: 4 },
//   { label: "IT Consultancy Service", value: 5 },
// ];

// function to get the selected value(to send to backend)
// const [value, setValue] = useState('')
// const  handleChange = (e)=>{
//     setValue(e.target.value)
// }
const ContactFormWeb = () => (
  <div>
    <section className="text-gray-700 body-font relative">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-12">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
            Get Started with us in your Digital Journey
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            We’d love to hear from you!
          </p>
        </div>
        <div className="lg:w-1/2 md:w-2/3 mx-auto">
          <div className="flex flex-wrap -m-2">
            <div className="p-2 w-full">
              <div className="relative">
                <label
                  htmlFor="name"
                  className="leading-7 text-sm text-gray-600"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  className="w-full bg-gray-100 rounded border border-gray-300 focus:border-green text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label
                  htmlFor="email"
                  className="leading-7 text-sm text-gray-600"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  className="w-full bg-gray-100 rounded border border-gray-300 focus:border-green text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <label
                htmlFor="service"
                className="leading-7 text-sm text-gray-600"
              >
                Service Request
              </label>
              {/* <select
                  className="w-full bg-gray-100 rounded border border-gray-300 focus:border-green text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  name="options"
                  id="options"
                  defaultValue="0" // Set default value to 0 (index of "Select service")
                >
                  <option value="0">Select service</option>  {/* First option with "Select service" */}
              {/* {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select> */}
              <Select
                label="Select Service"
                id="select"
                name="select"
                className="w-full bg-gray-100 hover:text-blue"
              >
                <Option>User Interface and UX Design</Option>
                <Option>Web Application Development</Option>
                <Option>Backend and API Development</Option>
                <Option>IT Consultancy Service</Option>
                <Option>Mobile Application Development</Option>
                <Option>Other</Option>
              </Select>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label
                  htmlFor="message"
                  className="leading-7 text-sm text-gray-600"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  className="w-full bg-gray-100 rounded border border-gray-300 focus:border-green h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                ></textarea>
              </div>
            </div>
            <div className="p-2 w-full">
              <button className="flex mx-auto text-white bg-blue border-0 py-2 px-8 focus:outline-none hover:bg-green rounded text-lg">
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default ContactFormWeb;
