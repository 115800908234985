import axios from "axios";
import React, { useEffect, useState } from "react";
import { Toaster } from 'react-hot-toast';
import ClipLoader from "react-spinners/ClipLoader";
import NoData from "../assets/no_data.svg";
import BlogMobile from "../components/mobile/BlogMobile";
import BlogWeb from "../components/web/BlogWeb";


const Blog = () => {
    let [blogs, setBlogs] = useState([]);
    let [latest, setLatest] = useState([]);
    let [popular, setPopular] = useState([]);
    let [loading, setLoading] = useState(false);
    const [ip, setIP] = useState("");

    let handlePageClick = () => { }
    let currentItems = []
    let pageCount = 0

    const getUrl = () => {
        const mode = process.env.REACT_APP_MODE;
        if (mode === 'prod') {
            return process.env.REACT_APP_PROD_BACKEND;
        } else {
            return process.env.REACT_APP_DEV_BACKEND;
        }
    }
  
    useEffect(() => {
        window.scrollTo(0, 0);
        setLoading(true);

        Promise.all([
            axios.get(`${getUrl()}/blogs-api/blogs/`),
            axios.get(`${getUrl()}/blogs-api/blogs/most-popular-posts/`),
            axios.get(`${getUrl()}/blogs-api/blogs/latest-posts/`),
            axios.get("https://api.ipify.org/?format=json")
        ]).then(([blogsResponse, popularResponse, latestResponse, ipResponse]) => {
            console.log(blogsResponse.data);
            console.log(popularResponse.data);
            console.log(latestResponse.data);
            console.log(ipResponse.data);

            setBlogs(blogsResponse.data.reverse());
            setPopular(popularResponse.data);
            setLatest(latestResponse.data);
            setIP(ipResponse.data.ip);

            setLoading(false);
        }).catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
        });
    }, []);

    const itemsPerPage = 6
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    if (blogs?.length) {
        currentItems = blogs.slice(itemOffset, endOffset);
        pageCount = Math.ceil(blogs.length / itemsPerPage);
        handlePageClick = (event) => {
            const newOffset = (event.selected * itemsPerPage) % blogs.length;
            setItemOffset(newOffset);
        };
    }


    return (
        <div className="w-full">
            <div ><Toaster /></div>
            {loading ? (
                <div className="h-screen">
                    <div className="h-screen flex items-center justify-center absolute inset-0 z-10">
                        <ClipLoader
                            color="#166938"
                            loading={loading}
                            size={150}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                </div>
            ) : blogs?.length > 0 && popular?.length > 0 && latest?.length > 0 ? (
                <div>
                    <div className="hidden xs:block">
                        <BlogWeb ip={ip} latest={latest} popular={popular} currentItems={currentItems} pageCount={pageCount} handlePageClick={handlePageClick} />
                    </div>
                    <div className="xs:hidden">
                        <BlogMobile ip={ip} currentItems={currentItems} pageCount={pageCount} handlePageClick={handlePageClick} />
                    </div>
                </div>
            ) : (
                <div className="flex-grow flex items-center justify-center">
                    <div className="flex flex-col items-center my-[10vh]">
                        <img src={NoData} alt="No Data" className="w-[25vh] h-auto mb-4" />
                        <p className="text-sm xs:text-lg font-semibold text-center">Oops! It looks like there are no blog posts available at the moment. Check back soon for updates or explore our other content.</p>
                    </div>
                </div>




            )}
        </div>
    );
}

export default Blog;