import { Carousel, IconButton } from "@material-tailwind/react";
import IonIcon from "@reacticons/ionicons";
import React from "react";

const ServiceTypeMobile = (props) => {

    return (
        <Carousel autoplay={true} autoplayDelay={3000} loop={true} transition={{ duration: 0 }}
            prevArrow={({ handlePrev }) => (
                <IconButton
                    variant="text"
                    color="blue"
                    size="lg"
                    onClick={handlePrev}
                    className="!absolute top-2/4 left-4 -translate-y-2/4">
                    <IonIcon className="text-2xl text-blue hover:text-green" name='chevron-back-circle-outline' />
                </IconButton>
            )}
            nextArrow={({ handleNext }) => (
                <IconButton
                  variant="text"
                  color="blue"
                  size="lg"
                  onClick={handleNext}
                  className="!absolute top-2/4 !right-4 -translate-y-2/4">
                    <IonIcon className="text-2xl text-blue hover:text-green" name='chevron-forward-circle-outline' />
                </IconButton>
            )}
            className="rounded-xl w-[50vh] xs:w-[60vh]">
            {props.applications.map((application) =>
                <div className="flex justify-center items-center mb-5">
                    <div className="bg-[#F3F2F3] shadow-xl rounded-md w-[30vh] h-fit pb-4 m-5 hover:shadow-2xl">
                        <div className="flex justify-center items-center">
                            <img src={application.image} alt="" className=" w-[25vh] h-[25vh]" />
                        </div>
                        <p className="text-sm text-center  mt-3 gradient-text px-[2vh]">{application.text}</p>
                    </div>
                </div>
            )}
        </Carousel>
    );
}

export default ServiceTypeMobile;