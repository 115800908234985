import IonIcon from "@reacticons/ionicons";
import React from "react";
import { Link } from "react-router-dom";
import slugify from "slugify";
import ServicesBanner from "../../assets/services_banner.png";
import { FloatingButton, WhatsAppWidget } from "../general";
import ContactBanner from "../general/ContactBanner";
import TopBannerMobile from "./TopBannerMobile";

const ServicesMobile = (props) => {
  return (
    <div className="w-full mb-5">
      <TopBannerMobile
        img={ServicesBanner}
        title="Software Development Services<br /> From Concept To Completion"
        caption="Work with us and transfrom your dream into reality"
      />

      <div className="mx-3 mt-10">
        <div className="grid grid-cols-1 md:grid-cols-3  m-5 ">
          {props.services.map((service, index) => (
            <Link
              key={service.title}
              to={"/services/" + slugify(service.title.toLocaleLowerCase())}
            >
              <div className="flex flex-col justify-start items-start p-4">
                <div>{service.image}</div>
                <div>
                  <p className="text-xl font-semibold">{service.title}</p>
                </div>
                <div className="">
                  <p>{service.content}</p>
                  <IonIcon
                    className="text-2xl text-blue hover:text-green"
                    name="arrow-forward-circle-sharp"
                  />
                </div>
              </div>
            </Link>
          ))}
        </div>

        <ContactBanner />
        <FloatingButton />
        <WhatsAppWidget />
      </div>
    </div>
  );
};

export default ServicesMobile;
