import React from "react";
import MobileBanner from "../../assets/mobile_banner.png";
import { FloatingButton, WhatsAppWidget } from "../general";
import BenefitsMobile from "./BenefitsMobile";
import ServiceTypeMobile from "./ServiceTypeMobile";
import TechnologiesMobile from "./TechnologiesMobile";
import TopBannerMobile from "./TopBannerMobile";

const MobileAppDevMobile = (props) => {
  return (
    <div className="w-full mb-5">
      <TopBannerMobile
        img={MobileBanner}
        title="Mobile application development<br /> services"
        caption="Create a mobile application that will revolutionise your industry"
      />

      <div className="flex flex-col justify-center items-center mt-8">
        <p className="text-ss text-center text-blue mt-[3vh]">
          Stand out in the market
        </p>
        <p className="text-sm font-light p-4">
          With custom software development, our clients can enhance their mobile
          in a comprehensive way thanks to our expertise in developing mobile
          and tablet applications. We assist companies with the design and
          development of unique mobile applications, giving them insightful data
          that helps them spur innovation and commercial expansion. Clients may
          improve their mobile presence and beat the competition with our help.
          We offer comprehensive assistance, encompassing ideation, organizing,
          executing, and overseeing, to guarantee that customers maximize the
          potential of their mobile apps.
        </p>
        <div className="bg-[#F8F9F9] w-full mt-5">
          <div className="flex justify-center items-center">
            <p className="text-blue text-sm  m-8 text-center">
              Benefits of mobile & tablet applications for business
            </p>
          </div>
          <BenefitsMobile benefits={props.benefits} />
        </div>

        <div>
          <div className="flex justify-center items-center mt-5 mx-3">
            <p className="text-blue text-ss m-5 text-center">
              Types of mobile & tablet applications we develop
            </p>
          </div>
          <ServiceTypeMobile applications={props.applications} />
        </div>

        <div className="bg-[#F8F9F9] w-full mt-[5vh]">
          <div className="flex flex-col justify-center items-center mt-[2vh]">
            <p className="text-blue text-ss text-center  mt-4">
              Technologies we use
            </p>
            <p className="text-sm m-4  font-light">
              We have the ability to work with the following frameworks and
              technologies, depending on your project requirements:
            </p>
          </div>
          <TechnologiesMobile technologies={props.technologies} />
        </div>
      </div>
      <FloatingButton />
      <WhatsAppWidget />
    </div>
  );
};

export default MobileAppDevMobile;
