import React from "react";
import IonIcon from "@reacticons/ionicons";

// function to fecth FAQ and icon from teh FAQ function.
const FaqItem = ({ question, answer, iconName }) => (
  <li className="text-left mb-10">
    <div className="flex flex-row items-start mb-5">
      <div className="hidden sm:flex items-center justify-center p-3 mr-3 rounded-full bg-blue text-white border-4 border-white text-xl font-semibold">
        <IonIcon className="text-xl" name={iconName} />
      </div>
      <div className="bg-gray-100 p-5 px-10 w-full flex items-center">
        <h4 className="text-md leading-6 font-medium text-black">{question}</h4>
      </div>
    </div>
    <div className="flex flex-row items-start">
      <div className="bg-indigo-100 p-5 px-10 w-full flex items-center">
        <p className="text-black-2 text-sm">{answer}</p>
      </div>
      <div className="hidden sm:flex items-center justify-center p-3 ml-3 rounded-full bg-green text-white border-4 border-white text-xl font-semibold">
        <IonIcon className="text-xl" name="chatbox-ellipses-outline" />
      </div>
    </div>
  </li>
);

// FAQ questions function to return the questions array
const Faq = () => {
  const questions = [
    {
      question: "What kind of software development services do you offer?",
      answer:
        "We offer a wide range of services, including custom development, mobile apps, web apps, and more!",
      iconName: "build-outline",
    },
    {
      question: "How long will it take to develop my software?",
      answer:
        "The timeline varies depending on project complexity. We'll provide a more accurate estimate after understanding your needs.",
      iconName: "time-outline",
    },
    {
      question: "Do you offer ongoing maintenance and support for my software?",
      answer:
        "Yes, we have plans to keep your software running smoothly and up-to-date.",
      iconName: "shield-checkmark-outline",
    },
  ];
  return (
    <div className="py-4 bg-white">
      <div className="max-w-screen-md mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-between">
        <div className="text-center">
          <p className="mt-4 text-sm leading-7 text-gray-500 font-regular">
            F.A.Q
          </p>
          <h3 className="text-3xl sm:text-4xl leading-normal font-extrabold tracking-tight text-black">
            Frequently Asked <span className="text-blue">Questions</span>
          </h3>
        </div>
        <div className="mt-20">
          <ul className="">
            {questions.map((faq) => (
              <FaqItem
                key={faq.question}
                question={faq.question}
                answer={faq.answer}
                iconName={faq.iconName}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Faq;
