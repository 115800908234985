import React from "react";
import { juda, moses, ted, val } from "../../assets";
import ImageCard from "./ImageCard";

const founders = [
  {
    image: moses,
    title: "Strategy and Operations Lead",
    name: "Moses Nielsen",
  },
  { image: juda, title: "Technical Lead", name: "Mark Judah" },
  { image: ted, title: "Sales and Marketing Lead", name: "Teddy Otieno" },
  {
    image: val,
    title: "Customer Operations Lead",
    name: "Valentine Khaseke",
  },
];

const Team = () => {
  return (
    <section className="justify-center text-center flex flex-col mt-8 items-center">
      <h1 className="justify-center text-2xl md:text-4xl font-bold text-center text-black mb-4">
        Our Founders
      </h1>
      <div className="flex flex-wrap md:flex-row mb-1 justify-center items-center md:justify-between">
        {founders.map((founder, index) => (
          <ImageCard key={index} imgSrc={founder.image}>
            <div className="flex flex-col justify-end items-center mt-4">
              <h3 className="text-xl font-bold mb-2">{founder.name}</h3>
              <p>{founder.title}</p>
            </div>
          </ImageCard>
        ))}
      </div>
    </section>
  );
};

export default Team;
