import React from "react";
import { BsTwitterX } from "react-icons/bs";
import { FaFacebook, FaInstagram, FaLinkedin, FaTiktok } from "react-icons/fa";
import { Link } from "react-router-dom";

const SocialIcons = () => {
  return (
    <div className="flex flex-row space-x-2 lg:my-10 md:my-8 my-2">
      <a
        href="https://www.facebook.com/jasiri.labs/"
        rel="noreferrer"
        target="_blank"
        className="p-2 text-gray-dark text-xl flex items-center justify-center hover:text-blue transition duration-300 ease-in-out"
      >
        <FaFacebook />
      </a>
      <a
        href="https://www.tiktok.com/@jasirilabs"
        target="_blank"
        rel="noreferrer"
        className="p-2 text-gray-dark text-xl flex items-center justify-center  hover:text-black transition duration-300 ease-in-out"
      >
        <FaTiktok />
      </a>
      <a
        href="https://www.instagram.com/jasirilabs/?next=%2F"
        target="_blank"
        rel="noreferrer"
        className="p-2 text-gray-dark text-xl flex items-center justify-center hover:text-ig transition duration-300 ease-in-out"
      >
        <FaInstagram />
      </a>
      <a
        href="https://www.linkedin.com/company/jasiri-labs/"
        target="_blank"
        rel="noreferrer"
        className="p-2 text-gray-dark text-xl flex items-center justify-center hover:text-blue transition duration-300 ease-in-out"
      >
        <FaLinkedin />
      </a>
      <a
        href="https://twitter.com/LabsJasiri"
        target="_blank"
        rel="noreferrer"
        className="p-2 text-gray-dark text-xl flex items-center justify-center hover:text-black transition duration-300 ease-in-out"
      >
        <BsTwitterX />
      </a>
    </div>
  );
};

export default SocialIcons;
