import React from "react";
import UiUxBanner from "../../assets/ui_ux_banner.png";
import FloatingButton from "../general/FloatingButton";
import WhatsAppWidget from "../general/WhatsApp";
import BenefitsWeb from "./BenefitsWeb";
import ServiceTypeWeb from "./ServiceTypeWeb";
import TechnologiesWeb from "./TechnologiesWeb";
import TopBannerWeb from "./TopBannerWeb";
const UiUxWeb = (props) => {
  return (
    <div>
      <TopBannerWeb
        img={UiUxBanner}
        title="User Interface & User Experience<br /> services"
        caption="The interface is your product's trademark"
      />
      <div className="flex flex-col justify-center items-center mt-8">
        <p className="text-3xl xl:text-5xl mt-[8vh] text-blue">
          Every pixel placed with purpose.
        </p>
        <p className="text-2xl mx-10 lg:mx-[15vh] mt-8 mb-10 font-light">
          A well-thought-out visual concept is the cornerstone of a decent
          interface, but a successful product requires much more. Making the
          user think as little as possible while navigating your program is the
          essence of good user interface design. To make it possible, Jasiris's
          designers provide solutions that enhance the interface's usability and
          clarity without sacrificing its distinctive aesthetic.
        </p>
        <BenefitsWeb benefits={props.benefits} />

        <div>
          <div className="flex justify-center items-center">
            <p className="text-blue text-3xl xl:text-5xl mt-[8vh] mb-5">
              Types of design Services we provide
            </p>
          </div>
          <ServiceTypeWeb applications={props.applications} />
        </div>

        <div className="bg-[#F8F9F9] w-full mt-[5vh]">
          <div className="flex flex-col justify-center items-center mt-[6vh]">
            <p className="text-blue text-3xl xl:text-5xl text-center  mt-4">
              Technologies we use
            </p>
            <p className="text-2xl mx-5 lg:mx-[20vh] m-4 text-center  font-light">
              We have the ability to work with the following frameworks and
              technologies, depending on your project requirements:
            </p>
          </div>
          <TechnologiesWeb technologies={props.technologies} />
        </div>
      </div>
      <WhatsAppWidget />
      <FloatingButton />
    </div>
  );
};

export default UiUxWeb;
