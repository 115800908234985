import { discount, robot } from "../../assets";
import styles from "../../pages/style";
import GetStarted from "./GetStarted";
import { Link } from "react-router-dom";

const Hero = () => (
  <section id="home" className={`flex md:flex-row flex-col ${styles.paddingY}`}>
    <div
      className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6`}
    >
      <div className="flex flex-row items-center py-[6px] px-4 bg-discount-gradient rounded-[10px] mb-2">
        <img src={discount} alt="discount" className="w-[32px] h-[32px]" />
        <p className={`${styles.paragraph} ml-2`}>
          <span className="text-white">Trusted by</span>{" "}
          <span className="text-blue">100% </span>
          <span className="text-white">of our clients</span>
        </p>
      </div>
      <div className="flex flex-row justify-between items-center w-full">
        <h1 className="flex-1 font-poppins font-semibold ss:text-[65px] text-[45px] text-black-2 ss:leading-[100.8px] leading-[75px]">
          Empowering <br className="sm:block hidden" />{" "}
          <span className="bg-gradient-to-r from-green to-blue bg-clip-text text-transparent">
            Innovative
          </span>{" "}
        </h1>
        <div className="ss:flex hidden md:mr-4 mr-0">
          <Link to="contact-us">
            <GetStarted />
          </Link>
        </div>
      </div>

      <h1 className="font-poppins font-semibold ss:text-[55px] text-[40px] text-black-2 w-full">
        Software Solutions.
      </h1>
      <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
        Partner with us to turn your ideas into reality and stay ahead in
        today's competitive digital landscape.
      </p>
    </div>

    <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative`}>
      <img src={robot} alt="billing" className="w-[100%] h-[100%] relative" />
    </div>

    <div className={`ss:hidden ${styles.flexCenter}`}>
      <GetStarted />
    </div>
  </section>
);

export default Hero;
