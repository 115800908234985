import IonIcon from "@reacticons/ionicons";
import React from "react";

const ServiceTypeWeb = (props) => {
    return (
        <div className="flex justify-center items-center">
            <div className="grid grid-cols-2 lg:grid-cols-3">
                {props.applications.map((application) =>
                    <div className="bg-[#F3F2F3] shadow-xl rounded-md w-[27vh] h-fit pb-5 m-5  hover:shadow-2xl">
                        <div className="flex justify-center items-center mt-4">
                            <img src={application.image} alt="" className=" w-[20vh] h-[20vh]" />
                        </div>
                        <p className="text-xl lg:text-sm text-center mt-2 gradient-text px-[2vh]">{application.text}</p>

                    </div>
                )}
            </div>
        </div>
    );
}

export default ServiceTypeWeb;