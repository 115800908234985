import React from "react";
import ITConsultancyBanner from "../../assets/it_consulting_banner.png";
import { FloatingButton, WhatsAppWidget } from "../general";
import ContactBanner from "../general/ContactBanner";
import BenefitsWeb from "./BenefitsWeb";
import ServiceTypeWeb from "./ServiceTypeWeb";
import TopBannerWeb from "./TopBannerWeb";
const ITConsultancyWeb = (props) => {
  return (
    <div>
      <TopBannerWeb
        img={ITConsultancyBanner}
        title="Information Technology Consultancy<br /> services"
        caption="Harmonize Your IT Strategy"
      />
      <div className="flex flex-col justify-center items-center mt-8">
        <p className="text-3xl xl:text-5xl mt-[8vh] text-blue">
          Boost your competitive edge through consultancy
        </p>
        <p className="text-xl mx-10 lg:mx-[15vh] mt-8  font-light">
          Embark on a revolutionary IT adventure with Jasiri Labs. We
          collaborate with you to improve your software architecture and create
          a tech-focused digital roadmap—we don't just offer advice. Our
          software experts guarantee a smooth digital transformation by
          streamlining your software portfolio, from strategic planning to
          accurate implementation.
        </p>
        <BenefitsWeb benefits={props.benefits} />

        <div>
          <div className="flex justify-center items-center">
            <p className="text-blue text-3xl xl:text-5xl mt-[8vh] mb-5">
              Types of consultancy services we provide
            </p>
          </div>
          <ServiceTypeWeb applications={props.applications} />
        </div>

        <ContactBanner />
        <FloatingButton />
        <WhatsAppWidget />
      </div>
    </div>
  );
};

export default ITConsultancyWeb;
