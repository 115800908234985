import React from "react";

import { Carousel, IconButton } from "@material-tailwind/react";
import IonIcon from "@reacticons/ionicons";
import { Link } from "react-router-dom";
import PortfolioBanner from "../../assets/portfolio_banner.jpg";
import ContactBanner from "../../components/general/ContactBanner";
import { clients } from "../../constants";
import { FloatingButton, WhatsAppWidget } from "../general";
import OurClients from "./OurClients";

const PortfolioWeb = () => {
  return (
    <div className="w-full mb-5">
      <div className="relative bg-gradient-to-r from-blue to-green">
        <img
          src={PortfolioBanner}
          alt=""
          className="h-[35vh] lg:h-[55vh] xl:h-[50vh] w-full opacity-30"
        />
        <div className="flex  absolute top-[15vh] left-[10vh] lg:left-[20vh] inset-0 z-10">
          <div className="flex flex-col  h-fit">
            <p className="text-3xl xl:text-6xl text-white font-semibold">
              Proven Development Partner for
              <br /> businesses
            </p>
            <p className="text-white text-xl xl:text-3xl mt-3 my-2">
              Work with us and transfrom your dream into reality
            </p>
            <Link to="/contact-us/">
              <div className="rounded-r-full rounded-bl-full text-sm xl:text-xl bg-gradient-to-r from-green to-blue text-white p-4 mt-3 w-fit">
                <p>Get a quote</p>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <OurClients />

      <div className="flex flex-col justify-center items-center my-[5vh] bg-[#F8F9F9]">
        <p className="text-3xl xl:text-5xl mt-[5vh] mb-5 font-poppins font-semibold text-blue">
          Our Work
        </p>
        <Carousel
          autoplay={true}
          autoplayDelay={3000}
          loop={true}
          transition={{ duration: 0 }}
          prevArrow={({ handlePrev }) => (
            <IconButton
              variant="text"
              color="green"
              size="lg"
              onClick={handlePrev}
              className="!absolute top-2/4 left-4 -translate-y-2/4"
            >
              <IonIcon
                className="text-4xl text-green hover:text-green"
                name="chevron-back-circle-outline"
              />
            </IconButton>
          )}
          nextArrow={({ handleNext }) => (
            <IconButton
              variant="text"
              color="green"
              size="lg"
              onClick={handleNext}
              className="!absolute top-2/4 !right-4 -translate-y-2/4"
            >
              <IonIcon
                className="text-4xl text-green hover:text-green"
                name="chevron-forward-circle-outline"
              />
            </IconButton>
          )}
          className="rounded-xl w-[120vh] mb-[10vh]"
        >
          {clients.map((client) => (
            <img
              src={client.screenshot}
              alt=""
              className="p-2 w-[120vh] h-[70vh]"
            />
          ))}
        </Carousel>
      </div>

      <ContactBanner />
      <FloatingButton />
      <WhatsAppWidget />
    </div>
  );
};

export default PortfolioWeb;
