import { Button } from "@material-tailwind/react";
import styles from "../../pages/style";
import { Link } from "react-router-dom";

const CTA = () => (
  <section
    className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} sm:flex-row flex-col bg-black-6 rounded-[20px] box-shadow`}
  >
    <div className="flex-1 flex flex-col">
      <h2 className={styles.heading3}>Let’s try our service now!</h2>
      <p className={`${styles.paragraph1} max-w-[470px] mt-5`}>
        We believe in the power of innovation to make a positive impact, and we
        invite you to join us on this exciting journey.
      </p>
    </div>

    <div className={`${styles.flexCenter} sm:ml-10 ml-0 sm:mt-0 mt-10`}>
      <Link to="contact-us">
        <Button
          variant="filled"
          className=" bg-blue w-[128.86px] h-[42.05px] object-contain mr-5 cursor-pointer transition-transform hover:scale-110 duration-500 ease-out justify-center p-1"
        >
          Get started
        </Button>
      </Link>
    </div>
  </section>
);

export default CTA;
