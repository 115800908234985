import { Card, CardBody, Typography } from "@material-tailwind/react";
import IonIcon from "@reacticons/ionicons";
import React from "react";
import toon from "../../assets/toon.jpg";
import SocialIcons from "../general/socialIcons";
import { Link } from "react-router-dom";

const ContactPageWeb = () => (
  <section
    className={`flex-col md:h-80 h-auto w-full bg-cover bg-center bg-blend-overlay `}
    style={{
      backgroundImage: `url(${toon})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }}
  >
    <div className="w-full flex justify-center mx-8 items-center md:flex-row flex-col pt-1">
      <h1 className="sm:text-3xl text-2xl font-bold title-font mb-0 text-gray-900 mr-4">
        {" "}
        Contact Details
      </h1>
      <div className="">
        <SocialIcons />
      </div>
    </div>
    <div className="w-full flex md:flex-row flex-col sm:my-2 justify-evenly">
      <div>
        <Card className="flex flex-row justify-center mt-6 my-2 w-full">
          <CardBody className="flex justify-between space-x-2">
            <IonIcon className="text-2xl" name="call-outline"></IonIcon>
            <Typography variant="h5" color="blue-gray">
              Phone:
            </Typography>
            <Typography>
              <Link to="tel:+254711793446" className="text-blue cursor-pointer">
                +254711793446
              </Link>
            </Typography>
          </CardBody>
        </Card>
      </div>
      <div>
        <Card className="w-full my-2">
          <CardBody className="flex flex-row justify-center items-center space-x-2">
            <IonIcon className="text-2xl" name="compass-outline"></IonIcon>
            <Typography variant="h5" color="blue-gray">
              Address:
            </Typography>
            <Typography className="flex flex-row justify-center mt-2 text-black-600 text-blue">
              First Floor Room 1,Swan Villa.
              <br /> Utawala Road,Nairobi
            </Typography>
          </CardBody>
        </Card>
      </div>

      <div>
        <Card className="mt-6 my-2 w-full">
          <CardBody className="flex justify-center items-center space-x-2">
            <IonIcon
              className="text-2xl ss:text-xl"
              name="mail-outline"
            ></IonIcon>
            <Typography variant="h5" color="blue-gray">
              Email:
            </Typography>
            <Typography>
              <a
                href="mailto:info@jasirilabs.tech"
                className="text-blue cursor-pointer"
              >
                info@jasirilabs.tech
              </a>
            </Typography>
          </CardBody>
        </Card>
      </div>
    </div>
  </section>
);

export default ContactPageWeb;
