import { Link } from "react-router-dom";

const TopBannerMobile = (props) => {

    return (
        <div className="relative bg-gradient-to-r from-blue to-green">
            <img src={props.img} alt="" className="h-[35vh] xxs:h-[40vh] w-full opacity-30" />
            <div className="flex  absolute top-[5vh] xxs:top-[10vh] left-[2vh] inset-0 z-10">
                <div className="flex flex-col  h-fit">
                    <p className="text-xs  xxs:text-xl text-white font-semibold" dangerouslySetInnerHTML={{ __html: props.title }} />
                    <p className="text-white text-[8px] xxs:text-xs mt-3" dangerouslySetInnerHTML={{ __html: props.caption }} />
                    <Link to="/contact-us/">
                        <div className="rounded-r-full rounded-bl-full text-sm xl:text-xl bg-gradient-to-r from-green to-[#356bb3] text-white p-4 mt-3 w-[20vh]">
                            <p className="text-center text-[8px] xxs:text-sm">Get a quote</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}
export default TopBannerMobile;