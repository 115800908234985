import React from "react";
import { Link } from "react-router-dom";
import slugify from "slugify";

const TopBlogs = (props) => {

    console.log(props)
    return (
        <div className="flex justify-center items-center">
            <div className="grid grid-cols-2">
                <div className="flex flex-col justify-start items-start m-5 p-4 w-fit">
                    <div>
                        <p className="text-xl text-blue uppercase font-semibold p-2">{props.latest[0].blog_category}</p>
                    </div>
                    <div>
                        <Link to={"/blog/" + slugify(props.latest[0].headline.toLocaleLowerCase())} state={props.latest[0]}>
                            <p className="text-xl lg:text-5xl font-semibold hover:underline p-2">{props.latest[0].headline}</p>
                        </Link>
                    </div>
                    <div>
                        <p className="text-wrap p-2">{props.latest[0].subheadline}</p>
                    </div>
                    <div>
                        <img src={props.latest[0].blog_image} alt="" className="lg:h-[50vh] lg:w-[70vh] p-2" />
                    </div>

                </div>

                <div className="flex flex-col justify-start m-5 p-4">
                    <div>
                        <div className="flex flex-col">
                            <p className="font-semibold text-xl p-2">Top posts</p>
                            {props.popular.map((blog,index) =>
                                <Link key={index} to={"/blog/" + slugify(blog.headline.toLocaleLowerCase())} state={blog}>
                                    <div className="flex justify-start items-start">
                                        <img src={blog.blog_image} alt="" className="h-30 w-40" />
                                        <div className="flex flex-col justify-start items-start m-5 hover:underline p-4">
                                            <div>
                                                <p className="text-xl text-green">{blog.blog_category}</p>
                                            </div>
                                            <div>
                                                <p className="text-sm">{blog.headline}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBlogs;