import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import ClipLoader from "react-spinners/ClipLoader";
import logo from "../assets/logobg.png";
import { Link } from "react-router-dom";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    if (localStorage.getItem("user")) {
      setLoading(false);
      window.location.replace(`${getUrl("redirect")}/admin-dash`);
    }
    setLoading(false);
  });
  const getUrl = (action) => {
    const mode = process.env.REACT_APP_MODE;
    if (mode === "prod") {
      if (action === "token") {
        return process.env.REACT_APP_PROD_BACKEND;
      } else {
        return process.env.REACT_APP_PROD_FRONTEND;
      }
    } else {
      if (action === "token") {
        return process.env.REACT_APP_DEV_BACKEND;
      } else {
        return process.env.REACT_APP_DEV_FRONTEND;
      }
    }
  };

  const onSubmit = (data) => {
    setLoading(true);
    let loginCreds = {
      email: data.email,
      password: data.password,
    };
    axios
      .post(`${getUrl("token")}/accounts-api/token/`, loginCreds)
      .then((response) => {
        setLoading(false);
        console.log(response);
        localStorage.setItem("user", {
          token: response.data.token,
        });
        window.location.replace(`${getUrl("redirect")}/admin-dash`);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            toast.error("The username or password is incorrect");
            localStorage.clear();
          }
          if (error.toJSON().message === "Network Error") {
            alert("no internet connection");
          }
        } else {
          console.log(error);
        }
      });
  };

  return (
    <div className="flex justify-center items-center w-full my-[10vh]">
      <div>
        <Toaster />
      </div>
      {loading ? (
        <div className="h-screen flex items-center justify-center absolute inset-0 z-10">
          <ClipLoader
            color="#166938"
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        ""
      )}
      <div className="px-6 py-4">
        <div className="flex justify-center mx-auto">
          <img src={logo} alt="logo" className="md:cursor-pointer h-16 "></img>
        </div>

        <h3 className="mt-3 text-xl font-medium text-center text-gray-600 dark:text-gray-200">
          Jasiri Labs
        </h3>

        <p className="mt-1 text-center text-gray-500 dark:text-gray-400">
          Login
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full mt-4">
            <input
              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500
                         bg-white border rounded-lg dark:bg-gray-800 dark:border-gray-600
                          dark:placeholder-gray-400 focus:border-blue-400 dark:focus:border-blue-300 
                          focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300"
              type="email"
              placeholder="Email Address"
              aria-label="Email Address"
              {...register("email", {
                required: true,
              })}
            />
            {errors.email && <span>This field is required</span>}
          </div>

          <div className="w-full mt-4">
            <input
              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500
                         bg-white border rounded-lg dark:bg-gray-800 dark:border-gray-600 
                         dark:placeholder-gray-400 focus:border-blue-400 dark:focus:border-blue-300 
                         focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300"
              type="password"
              placeholder="Password"
              aria-label="Password"
              {...register("password", { required: true })}
            />
            {errors.password && <span>This field is required</span>}
          </div>

          <div className="flex items-center justify-between mt-4">
            <Link
              to="#"
              className="text-sm text-gray-600 dark:text-gray-200 hover:text-gray-500"
            >
              Forget Password?
            </Link>

            <button className="px-6 py-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue rounded-lg hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
