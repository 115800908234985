import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import Footer from './components/general/Footer';
import Navbar from './components/general/Navbar';
import { AboutUs, BlogView, Blogs, ContactUs, Home, Login, Portfolio, ServiceView, Services } from './pages';
import DashboardHome from './pages/DashboardHome';




function App() {
  return (
    <BrowserRouter>
      <div className="flex flex-col font-poppins">
      {window.location.pathname !== '/admin-dash' && <Navbar />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog/:slug" element={<BlogView />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/:title" element={<ServiceView />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path='/about-us' element={<AboutUs />}></Route>
          <Route path="/login" element={<Login />} />
          <Route path="/admin-dash" element={<DashboardHome />} />
        </Routes>
        {window.location.pathname !== '/admin-dash' && <Footer />}

      </div>
      <Routes>
      </Routes>
    </BrowserRouter>
  );
}

export default App;