import { Button } from "@material-tailwind/react";
import card from "../assets/card.png";
import styles, { layout } from "../pages/style";
import { Link } from "react-router-dom";

const Service = () => (
  <section className={layout.section}>
    <div className={layout.sectionInfo}>
      <h2 className={styles.heading2}>
        What do we <br className="sm:block hidden" />
        have to Offer to your business?
      </h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
        At Jasiri Labs, we're passionate about crafting software solutions that
        propel your business forward. We don't just write code, we bridge the
        gap between your vision and reality.
      </p>

      <div className="flex flex-wrap sm:mt-10 mt-6">
        <Link to="about-us">
          <Button
            variant="filled"
            className=" bg-blue w-[128.86px] h-[42.05px] object-contain mr-5 cursor-pointer transition-transform hover:scale-110 duration-500 ease-out justify-center p-1"
          >
            Learn more
          </Button>
        </Link>
      </div>
    </div>

    <div className={layout.sectionImg}>
      <img src={card} alt="Cards" className="w-[100%] h-[100%]" />
    </div>
  </section>
);

export default Service;
