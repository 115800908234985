import IonIcon from "@reacticons/ionicons";
import React from "react";

const BenefitsWeb = (props) => {
    return (
        <div className="bg-[#F8F9F9] w-full mt-[5vh]">
            <div className="my-[5vh] lg:my-[12vh]">
                <p className="text-blue text-center text-3xl xl:text-5xl mb-8">{props.benefits[0].title}</p>
                <div className="flex justify-center items-center w-full">
                    <div className="grid grid-cols-2 lg:grid-cols-4">
                        {props.benefits.map((benefit) =>
                            <div className="bg-white shadow-2xl rounded-md w-[30vh] h-[12vh] m-4 p-6 lg:w-[35vh] lg:h-[15vh] flex flex-col justify-center items-center">
                                <IonIcon className="text-2xl xl:text-4xl text-blue hover:text-green" name={benefit.icon} />
                                <p className="text-xl lg:text-sm xl:text-2xl text-center font-medium p-1 xl:p-3 gradient-text">{benefit.text}</p>
                            </div>

                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BenefitsWeb;