import React from "react";
import UiUxBanner from "../../assets/ui_ux_banner.png";
import FloatingButton from "../general/FloatingButton";
import WhatsAppWidget from "../general/WhatsApp";
import BenefitsMobile from "./BenefitsMobile";
import ServiceTypeMobile from "./ServiceTypeMobile";
import TechnologiesMobile from "./TechnologiesMobile";
import TopBannerMobile from "./TopBannerMobile";

const UiUxMobile = (props) => {
  return (
    <div className="w-full mb-5">
      <TopBannerMobile
        img={UiUxBanner}
        title="User Interface & User Experience<br /> services"
        caption="The interface is your product's trademark"
      />

      <div className="flex flex-col justify-center items-center mt-8">
        <p className="text-ss text-center text-blue mt-[3vh]">
          Every pixel placed with purpose.
        </p>

        <p className="text-sm font-light p-4">
          A well-thought-out visual concept is the cornerstone of a decent
          interface, but a successful product requires much more. Making the
          user think as little as possible while navigating your program is the
          essence of good user interface design. To make it possible, Jasiris's
          designers provide solutions that enhance the interface's usability and
          clarity without sacrificing its distinctive aesthetic.
        </p>
        <div className="bg-[#F8F9F9] w-full mt-5">
          <div className="flex justify-center items-center">
            <p className="text-blue text-ss  m-8  text-center">
              Benefits of UI/UX services for business
            </p>
          </div>
          <BenefitsMobile benefits={props.benefits} />
        </div>

        <div>
          <div className="flex justify-center items-center mt-5">
            <p className="text-blue text-ss m-5 text-center">
              Types of design Services we provide
            </p>
          </div>
          <ServiceTypeMobile applications={props.applications} />
        </div>

        <div className="bg-[#F8F9F9] w-full mt-[5vh]">
          <div className="flex flex-col justify-center items-center mt-[2vh]">
            <p className="text-blue text-ss text-center  mt-4">
              Technologies we use
            </p>
            <p className="text-sm m-4  font-light">
              We have the ability to work with the following frameworks and
              technologies, depending on your project requirements:
            </p>
          </div>
          <TechnologiesMobile technologies={props.technologies} />
        </div>
      </div>
      <FloatingButton />
      <WhatsAppWidget />
    </div>
  );
};

export default UiUxMobile;
