import React from "react";
import WebBanner from "../../assets/web_banner.png";
import FloatingButton from "../general/FloatingButton";
import WhatsAppWidget from "../general/WhatsApp";
import BenefitsMobile from "./BenefitsMobile";
import ServiceTypeMobile from "./ServiceTypeMobile";
import TechnologiesMobile from "./TechnologiesMobile";
import TopBannerMobile from "./TopBannerMobile";

const WebAppDevMobile = (props) => {
  return (
    <div className="w-full mb-5">
      <TopBannerMobile
        img={WebBanner}
        title="Web application development<br /> services"
        caption="Develop a web application that leverages innovation"
      />

      <div className="flex flex-col justify-center items-center mt-8">
        <p className="text-ss text-center text-blue mt-[3vh]">
          Hire business-oriented web developers
        </p>
        <p className="text-sm font-light p-4">
          Our skilled team of web developers specializes in building unique web
          apps that are customized to match the specific requirements of our
          customers. We approach development with a user-centered mindset,
          making sure the finished product is user-friendly, intuitive, and
          satisfies our clients' unique needs. We collaborate closely with our
          clients to provide web-based solutions that are dependable, scalable,
          and successful in helping them achieve their business objectives, from
          the first concept to the last deployment.
        </p>
        <div className="bg-[#F8F9F9] w-full mt-5">
          <div className="flex justify-center items-center">
            <p className="text-blue text-sm  m-5 ">
              Benefits of web applications for business
            </p>
          </div>
          <BenefitsMobile benefits={props.benefits} />
        </div>

        <div>
          <div className="flex justify-center items-center mt-5 mx-3">
            <p className="text-blue text-ss m-5 text-center">
              Types of web applications applications we develop
            </p>
          </div>
          <ServiceTypeMobile applications={props.applications} />
        </div>

        <div className="bg-[#F8F9F9] w-full mt-[5vh]">
          <div className="flex flex-col justify-center items-center mt-[2vh]">
            <p className="text-blue text-ss text-center  mt-4">
              Technologies we use
            </p>
            <p className="text-sm m-4  font-light">
              We have the ability to work with the following frameworks and
              technologies, depending on your project requirements:
            </p>
          </div>
          <TechnologiesMobile technologies={props.technologies} />
        </div>
      </div>
      <FloatingButton />
      <WhatsAppWidget />
    </div>
  );
};

export default WebAppDevMobile;
