import React from "react";
import IonIcon from "@reacticons/ionicons";
import { PiHandshakeLight } from "react-icons/pi";
import values from "../../assets/values.jpg";

const Features = () => {
  return (
    <section className="">
      <div className="container max-w-xl p-6 mx-auto space-y-12 lg:px-8 lg:max-w-7xl">
        <div>
          <h2 className="text-3xl font-bold text-center sm:text-5xl">
            Our Values
          </h2>
          <p className="max-w-3xl mx-auto mt-4 text-xl text-center ">
            Our core values shape everything we do. They guide our decisions,
            inspire our actions, and drive us towards excellence.
          </p>
        </div>
        <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
          <div>
            <div className="mt-4 space-y-12">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 rounded-md">
                    <IonIcon
                      className="text-4xl text-blue"
                      name="bulb-outline"
                    ></IonIcon>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg font-medium leadi ">
                    Innovation and Creativity.
                  </h4>
                  <p className="mt-2">
                    We embrace innovation as the cornerstone of the success of
                    our. We constantly push boundaries, challenge the status
                    quo, and seek creative solutions to complex problems.
                    Innovation is at the heart of everything we create.
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 rounded-md">
                    <PiHandshakeLight className="text-4xl text-green" />
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg font-medium leadi ">Collaboration</h4>
                  <p className="mt-2 ">
                    Collaboration fuels our creativity and drives our success.
                    We believe in the power of teamwork and partnership, both
                    within our organization and with our clients. By working
                    together, we achieve greatness.
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 rounded-md">
                    <IonIcon
                      className="text-4xl text-green-500"
                      name="trophy"
                    ></IonIcon>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg font-medium leadi ">Excellence</h4>
                  <p className="mt-2 ">
                    Excellence is our standard. We strive for perfection in
                    everything we do, from the quality of our code to the level
                    of service we provide to our clients. We are committed to
                    delivering superior results and exceeding expectations.
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 rounded-md">
                    <IonIcon
                      className="text-4xl text-blue-gray-600"
                      name="wallet-outline"
                    ></IonIcon>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg font-medium leadi ">
                    Cost-effectiveness
                  </h4>
                  <p className="mt-2 ">
                    We believe that quality software solutions should be
                    accessible to all businesses, regardless of size or budget.
                    That's why we are committed to offering affordable pricing
                    options that deliver exceptional value for our clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div aria-hidden="true" className="mt-8 lg:mt-0">
            <img
              src={values}
              className="mx-auto rounded-lg shadow-lg dark-bg-gray-500"
              alt=""
            />
            {/* <img width="600" height="600" src="" style="color:transparent"> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
