import React from "react";
import MobileBanner from "../../assets/mobile_banner.png";
import { FloatingButton, WhatsAppWidget } from "../general";
import BenefitsWeb from "./BenefitsWeb";
import ServiceTypeWeb from "./ServiceTypeWeb";
import TechnologiesWeb from "./TechnologiesWeb";
import TopBannerWeb from "./TopBannerWeb";

const MobileAppDevWeb = (props) => {
  return (
    <div>
      <TopBannerWeb
        img={MobileBanner}
        title="Mobile application development<br /> services"
        caption="Create a mobile application that will revolutionise your industry"
      />

      <div className="flex flex-col justify-center items-center mt-8">
        <p className="text-3xl xl:text-5xl mt-[8vh] text-blue">
          Stand out in the market
        </p>
        <p className="text-xl mx-10 lg:mx-[15vh] mt-8  font-light">
          With custom software development, our clients can enhance their mobile
          in a comprehensive way thanks to our expertise in developing mobile
          and tablet applications. We assist companies with the design and
          development of unique mobile applications, giving them insightful data
          that helps them spur innovation and commercial expansion. Clients may
          improve their mobile presence and beat the competition with our help.
          We offer comprehensive assistance, encompassing ideation, organizing,
          executing, and overseeing, to guarantee that customers maximize the
          potential of their mobile apps.
        </p>
        <BenefitsWeb benefits={props.benefits} />

        <div>
          <div className="flex justify-center items-center">
            <p className="text-blue text-3xl xl:text-5xl mt-[8vh] mb-5">
              Types of mobile & tablet applications we develop
            </p>
          </div>
          <ServiceTypeWeb applications={props.applications} />
        </div>

        <div className="bg-[#F8F9F9] w-full mt-[5vh]">
          <div className="flex flex-col justify-center items-center mt-[6vh]">
            <p className="text-blue text-3xl xl:text-5xl text-center  mt-4">
              Technologies we use
            </p>
            <p className="text-2xl mx-5 lg:mx-[20vh] m-4 text-center  font-light">
              We have the ability to work with the following frameworks and
              technologies, depending on your project requirements:
            </p>
          </div>
          <TechnologiesWeb technologies={props.technologies} />
        </div>
      </div>
      <FloatingButton />
      <WhatsAppWidget />
    </div>
  );
};

export default MobileAppDevWeb;
