import React from "react";
import about from "../../assets/about.jpg";

const About = () => (
  <section
    className="bg-black-4 h-40 md:h-80 w-full bg-cover bg-center bg-blend-overlay drop-shadow-sm bg-gradient-to-tl from-green to-blue"
    style={{
      backgroundImage: `url(${about})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }}
  >
    <div className="flex items-center justify-center md:justify-start h-full">
      <div className="text-white">
        <h1 className="ml-8 opacity-80 md:text-5xl lg:text-5xl align-middle font-bold">
          Crafting Innovation
        </h1>
        <p className="md:text-start text-center text-blue md:text-2xl ml-8 bg-gradient-to-r from-blue to-green bg-clip-text text-transparent">
          About Us
        </p>
      </div>
    </div>
    <div className="page_banner_overlay"></div>
  </section>
);

export default About;
