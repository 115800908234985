import React from "react";

const TechnologiesWeb = (props) => {
    return (
        <div className="flex justify-center items-center">
            <div className="grid grid-cols-3 lg:grid-cols-4  lg:mx-[25vh]">
                {props.technologies.map((technology) =>
                    <div className="bg-white rounded-2xl m-[4vh] w-fit">
                        <img src={technology.image} alt="" className=" w-[15vh] h-[15vh] p-4 " />
                    </div>
                )}
            </div>
        </div>
    );
}

export default TechnologiesWeb;