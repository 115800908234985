import React from "react";

const ImageCard = ({ children, imgSrc, ...props }) => {
  return (
    <div
      {...props}
      className="relative xs:w-64  justify-between overflow-hidden rounded-2xl shadow-lg group mr-6 ml-6 mt-4 mb-4"
    >
      <img
        src={imgSrc}
        alt="Profile"
        className="transition-transform group-hover:scale-110 duration-200"
      />
      <div className="absolute p-4 justify-around inset-0 flex items-end bg-gradient-to-t from-black-1 to-transparent">
        <div className="p-4 text-white">{children}</div>
      </div>
    </div>
  );
};

export default ImageCard;
