import React, { useEffect } from "react";
import Analytics from "../assets/analytics.png";
import Booking from "../assets/booking.png";
import Custom from "../assets/custom.png";
import Django from "../assets/django.svg";
import Ecom from "../assets/ecom.png";
import Flutter from "../assets/flutter.svg";
import Go from "../assets/golang.svg";
import Jquery from "../assets/jquery.svg";
import LandingPages from "../assets/landing.png";
import Laravel from "../assets/laravel.svg";
import NewsFeed from "../assets/newsfeed.png";
import Nodejs from "../assets/node.svg";
import Php from "../assets/php.svg";
import Python from "../assets/python.svg";
import ReactFramework from "../assets/react.svg";
import Svelte from "../assets/svelte.svg";
import Vue from "../assets/vue.svg";
import WebAppDevMobile from "../components/mobile/WebAppDevMobile";
import WebAppDevWeb from "../components/web/WebAppDevWeb";



const WebAppDev = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const benefits = [
        {
            "title": "Benefits of web applications for business",
            'text': '24/7 accessibility',
            'icon': 'time-outline'
        },
        {
            'text': 'Increased staff productivity',
            'icon': 'barbell-outline'
        },
        {
            'text': 'Customisable and scalable',
            'icon': 'bar-chart-outline'
        },
        {
            'text': 'Quick deploment cycles',
            'icon': 'hourglass-outline'
        },

    ]

    const applications = [
        {
            'text': 'Websites & Landing Pages',
            'image': LandingPages
        },
        {
            'text': 'Ecommerce',
            'image': Ecom
        },
        {
            'text': 'Analytics Dashboards',
            'image': Analytics
        },
        {
            'text': 'Custom Enterprise Applications',
            'image': Custom
        },
        {
            'text': 'News, Blogs and Media Portals',
            'image': NewsFeed
        },
        {
            'text': 'Booking and Reservation Systems',
            'image': Booking
        },
    ]

    const technologies = [
        {
            'image': ReactFramework
        },
        {
            'image': Django
        },
        {
            'image': Svelte
        },
        {
            'image': Vue
        },
        {
            'image': Laravel
        },
        {
            'image': Nodejs
        },
        {
            'image': Jquery
        },
        {
            'image': Php
        },
        {
            'image': Go
        },
        {
            'image': Python
        },
        {
            'image': Flutter
        },

    ]

    return (
        <div className="w-full">
            <div className="hidden sm:block">
                <WebAppDevWeb service={props.service} benefits={benefits} applications={applications} technologies={technologies} />
            </div>
            <div className="sm:hidden">
                <WebAppDevMobile service={props.service} benefits={benefits} applications={applications} technologies={technologies} />
            </div>
        </div>
    );
}


export default WebAppDev;