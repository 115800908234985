import React, { useEffect } from 'react'
import Faq from '../components/general/Faq'
import FloatingButton from '../components/general/FloatingButton'
import WhatsAppWidget from '../components/general/WhatsApp'
import ContactBannerMobile from '../components/mobile/ContactBannerMobile'
import ContactFormMobile from '../components/mobile/ContactFormMobile'
import ContactPageMobile from '../components/mobile/ContactPageMobile'
import ContactBannerWeb from '../components/web/ContactBannerWeb'
import ContactFormWeb from '../components/web/ContactFormWeb'
import ContactPageWeb from '../components/web/ContactPageWeb'
// import Contact from '../components/general/Contact'
// import ContactForm from '../components/general/ContactForm'

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className="w-full">
            <div className="hidden sm:block">
                <ContactBannerWeb />
                <ContactPageWeb />
                <ContactFormWeb />
                <FloatingButton />
                <WhatsAppWidget />
                <Faq />
            </div>
            <div className="sm:hidden">
                <ContactBannerMobile />
                <ContactPageMobile />
                <ContactFormMobile />
                <FloatingButton />
                <WhatsAppWidget />
                <Faq />
            </div>
        </div>

    )
}

export default ContactUs