import React, { useEffect } from "react";
import PortfolioWeb from "../components/web/PortfolioWeb";
import PortfolioMobile from "../components/mobile/PortfolioMobile";


const Portfolio = () => {
   
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className="w-full">
            <div className="hidden sm:block">
                <PortfolioWeb />
            </div>
            <div className="sm:hidden">
                <PortfolioMobile/>
            </div>
        </div>
    );
}

export default Portfolio;