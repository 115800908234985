import { Input, Textarea } from "@material-tailwind/react";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from 'react-hot-toast';
import ClipLoader from "react-spinners/ClipLoader";
import TimeAgo from 'react-timeago';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";



const Comments = (props) => {
  let [loading, setLoading] = useState(false);

  const getUrl = () => {
    const mode = process.env.REACT_APP_MODE;
    if (mode === "prod") {
      return process.env.REACT_APP_PROD_BACKEND;
    } else {
      return process.env.REACT_APP_DEV_BACKEND;
    }
  };
  const {
    register,
    handleSubmit,
    reset,
  } = useForm();


  const onSubmit = (data) => {
    reset();
    setLoading(true);
    let commentData = {
      blog: props.id,
      name: data.name,
      comment: data.comment,
    };
    axios
      .post(`${getUrl()}/blogs-api/comments/`, commentData)
      .then((response) => {
        console.log(response);
        setLoading(false);
        Toastify({
          text: "Your comment has been received and is currently being reviewed. Check back shortly.",
          duration: 3000
        }).showToast();
      })
      .catch(function (error) {
        if (error.response) {
          setLoading(false);
          toast.error("An error occured");
          if (error.toJSON().message === "Network Error") {
            alert("no internet connection");
          }
        } else {
          console.log(error.response);
        }
      });
  };

  return (
    <div className="my-[4vh] mx-[5vh] lg:mx-[20vh]">

      <div className=" mb-[3vh]">
        <p className="text-2xl my-[4vh]">Share your thoughts</p>
        <div className="w-fit">
          <Toaster position="bottom-center" />
        </div>
        {loading ? (
          <div className="h-screen flex items-center justify-center absolute inset-0 z-10">
            <ClipLoader
              color="#166938"
              loading={loading}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          ""
        )}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-[35vh] sm:w-[50vh] lg:w-[90vh]"
        >
          <div className="w-full mb-5">
            <Input label="Name" {...register("name", { required: true })} />
          </div>

          <div className="relative">
            <div className="">
              <Textarea
                label="Comment"
                {...register("comment", { required: true })}
              />
            </div>
            <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none"></div>
          </div>
          <button
            type="submit"
            className="text-white p-3 rounded-xl bg-gradient-to-r from-green to-blue"
          >
            Comment
          </button>
        </form>
      </div>
      {
        props.comments.map((comment, index) => (
          comment.approved === true
            ? (
              <div class="bg-white p-4 rounded-lg shadow-md my-4">
                <h3 class="text-lg font-bold">{comment.name}</h3>
                <p class="text-gray-700 text-sm mb-2">Posted <TimeAgo date={comment.date_posted}/></p>
                <p class="text-gray-700">{comment.comment}
                </p>
              </div>
            ) : (
              console.log(comment.approved)
            )))}

    </div>


  );
};

export default Comments;
