import React from "react";
import BackendBanner from "../../assets/backend_banner.png";
import { FloatingButton, WhatsAppWidget } from "../general";
import BenefitsWeb from "./BenefitsWeb";
import ServiceTypeWeb from "./ServiceTypeWeb";
import TechnologiesWeb from "./TechnologiesWeb";
import TopBannerWeb from "./TopBannerWeb";

const BackendAndApiWeb = (props) => {
  return (
    <div>
      <TopBannerWeb
        img={BackendBanner}
        title="Backend and API intergration<br /> services"
        caption="Choose the right backend strategy"
      />
      <div className="flex flex-col justify-center items-center mt-8">
        <p className="text-3xl xl:text-5xl mt-[8vh] text-blue">
          A development company that knows your needs
        </p>
        <p className="text-xl mx-10 lg:mx-[15vh] mt-8  font-light">
          Our clients can design and construct custom software solutions that
          easily connect and integrate various systems thanks to our expertise
          in API development and integration. Our team of professionals can help
          you establish a networked ecosystem that facilitates data sharing and
          communication across your systems, increasing automation and overall
          efficiency. Our expertise lies in developing scalable and resilient
          API systems that can manage substantial volumes of data and traffic,
          guaranteeing a seamless implementation and uptake process for your
          company. To guarantee a flawless API integration, we also offer API
          creation, testing, and maintenance services.
        </p>

        <BenefitsWeb benefits={props.benefits} />

        <div>
          <div className="flex justify-center items-center">
            <p className="text-blue text-3xl xl:text-5xl mt-[8vh] mb-5">
              Types of backends and APIs we develop and integrate
            </p>
          </div>
          <ServiceTypeWeb applications={props.applications} />
        </div>

        <div className="bg-[#F8F9F9] w-full mt-[5vh]">
          <div className="flex flex-col justify-center items-center mt-[6vh]">
            <p className="text-blue text-3xl xl:text-5xl text-center  mt-4">
              Technologies we use
            </p>
            <p className="text-2xl mx-5 lg:mx-[20vh] m-4 text-center  font-light">
              We have the ability to work with the following frameworks and
              technologies, depending on your project requirements:
            </p>
          </div>
          <TechnologiesWeb technologies={props.technologies} />
        </div>
      </div>
      <FloatingButton />
      <WhatsAppWidget />
    </div>
  );
};

export default BackendAndApiWeb;
