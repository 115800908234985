import './footer.css'; // Import the custom CSS file
import FooterMobile from '../mobile/FooterMobile';
import FooterWeb from '../web/FooterWeb';
const Footer = () => {
    return (
        <div className="flex flex-col mt-auto">
            <div className="hidden sm:block">
                <FooterWeb />
            </div>
            <div className="sm:hidden">
                <FooterMobile />
            </div>
        </div>
    );
};

export default Footer;