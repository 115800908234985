import React, { useEffect } from "react";
import slugify from "slugify";
import BackendAndApi from "./BackendAndApi";
import WebAppDev from "./WebAppDev";
import MobileAppDev from "./MobileAppDev";
import ITConsultancy from "./ITConsultancy";
import UiUx from "./UiUx";


const ServiceView = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    let url = window.location.href
    let arr = url.split("/");
    let slug = arr[arr.length - 1]
    let words = slug.split('-')
    let service = ''
    words.map((word) =>
        service = slugify(service + " " + word)
    )

    switch (service) {
        case slugify('backend and api integration'):
            return (
                <div>
                    {<BackendAndApi service={service} />}
                </div>
            );
        case slugify('web application development'):
            return (
                <div>
                    {<WebAppDev service={service} />}
                </div>
            );
        case slugify('mobile application development'):
            return (
                <div>
                    {<MobileAppDev service={service} />}
                </div>
            );

        case slugify('user-interface-and-user-experience'):
            return (
                <div>
                    {<UiUx service={service} />}
                </div>
            );

        case slugify('it-consultancy-services'):
            return (
                <div>
                    {<ITConsultancy service={service} />}
                </div>
            );

        case slugify('custom-software-development'):
            return (
                <div>
                    {<ITConsultancy service={service} />}
                </div>
            );
        default:
            break;
    }


}
export default ServiceView;