import React from "react";
import Comments from "../../components/general/Comments";
import SocialIcons from "../../components/general/socialIcons";

import TimeAgo from 'react-timeago';
import FloatingButton from "../general/FloatingButton";



const BlogViewWeb = (props) => {
    console.log(props)
    return (
        <div className="w-full">
            <div>
                <div>
                    <div className="flex justify-center items-center">
                        <p className="flex text-4xl font-bold text-center mt-[6vh]">{props.blog.headline}</p>
                    </div>
                    <div className="flex items-center justify-center my-2 space-x-3 text-xl font-semibold">
                        <p className="capitalize">By {props.blog.author_name}</p>
                        <p>
                            Posted <TimeAgo date={props.blog.date_posted} />
                        </p>
                    </div>
                    <div className="flex flex-col justify-center items-center mt-4">
                        <img src={props.blog.blog_image} alt="" className="w-[50vh] lg:w-[70vh]" />
                        <div className="flex justify-start items-center space-x-5 mt-2">
                            <SocialIcons />
                        </div>
                        <p className="mx-[5vh] lg:mx-[20vh]  text-justify mt-4" dangerouslySetInnerHTML={{ __html: props.blog.blog_content }}></p>
                    </div>
                </div>
                <Comments comments={props.blog.comments} id={props.blog.id} />

            </div>
            <FloatingButton />
        </div>
    );
}

export default BlogViewWeb;