import React from "react";
import IdeaGreen from "../../assets/idea.svg"
import IdeaBlue from "../../assets/idea_blue.svg"

import { Link } from "react-router-dom";

let url = window.location.href
let arr = url.split("/");
let slug = arr[arr.length - 1]

let words = slug.split('-')
let page = ''
words.map((word) =>
    page = page + " " + word
)

const ContactBanner = () => {
    return (
        <div className="flex justify-center items-center  my-[5vh]">
            {page.trim() === 'portfolio' ? (
                <img src={IdeaGreen} alt="" className="p-2 w-[20vh] h-[20vh] sm:w-[30vh] sm:h-[30vh]" />
            ) : (
                <img src={IdeaBlue} alt="" className="p-2 w-[20vh] h-[20vh] sm:w-[30vh] sm:h-[30vh]" />
            )}
            <div>
                <p className="text-sm sm:text-3xl mt-4">Have a project in mind?</p>
                <Link to="/contact-us/">
                    <div className={`rounded-r-full rounded-bl-full text-sm sm:text-xl ${page.trim() === 'portfolio' ? 'bg-green' : 'bg-blue'} text-white p-3 mt-3 w-fit`}>
                    <p>Contact Us</p></div>
                </Link>
            </div>
        </div>
    );
}

export default ContactBanner;