import { Link } from "react-router-dom";

const TopBannerWeb = (props) => {

    return (
        <div className="relative bg-gradient-to-r from-blue to-green">
            <img src={props.img} alt="" className="h-[35vh] lg:h-[55vh] xl:h-[50vh] w-full opacity-30" />
            <div className="flex  absolute top-[15vh] left-[10vh] lg:left-[20vh] inset-0 z-10">
                <div className="flex flex-col  h-fit">
                    <div className="text-3xl xl:text-6xl text-white font-semibold" dangerouslySetInnerHTML={{ __html: props.title }} />
                    <p className="text-white text-xl xl:text-3xl mt-3 my-2" dangerouslySetInnerHTML={{ __html: props.caption }} />
                    <Link to="/contact-us/">
                        <div className="rounded-r-full rounded-bl-full text-sm xl:text-xl bg-gradient-to-r from-green to-blue text-white p-4 mt-3 w-fit">
                            <p>Get a quote</p></div>
                    </Link>
                </div>
            </div>
        </div>
    );
}
export default TopBannerWeb;