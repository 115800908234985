import React, { useEffect } from "react";
import Branding from "../assets/branding.png";
import Canva from "../assets/canva.svg";
import Figma from "../assets/figma.svg";
import Illustrator from "../assets/illustrator.svg";
import Photoshop from "../assets/photoshop.svg";
import Prototype from "../assets/prototype.png";
import Research from "../assets/research.png";
import Sketch from "../assets/sketch.svg";
import Usability from "../assets/usability.png";
import Design from "../assets/web_mobile.png";
import Wireframing from "../assets/wireframing.png";
import Xd from "../assets/xd.svg";
import UiUxMobile from "../components/mobile/UiUxMobile";
import UiUxWeb from "../components/web/UiUxWeb";


const UiUx = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const benefits = [
        {
            "title": "Benefits of UI/UX services for business",
            'text': 'Improves Usability',
            'icon': 'arrow-up-outline'
        },
        {
            'text': 'Increased brand credibility',
            'icon': 'diamond-outline'
        },
        {
            'text': 'Increases Productivity',
            'icon': 'barbell-outline'
        },
        {
            'text': 'Improves Search Engine Optimization',
            'icon': 'search-outline'
        },
    ]

    const applications = [
        {
            'text': 'Wireframing',
            'image': Wireframing
        },
        {
            'text': 'Prototypes',
            'image': Prototype
        },
        {
            'text': 'Usability testing',
            'image': Usability
        },
        {
            'text': 'User research and analyis',
            'image': Research
        },
        {
            'text': 'Branding',
            'image': Branding
        },
        {
            'text': 'Web and Mobile App UX/UI Design',
            'image': Design
        },
    ]

    const technologies = [
        {
            'image': Photoshop
        },
        {
            'image': Illustrator
        },
        {
            'image': Xd
        },
        {
            'image': Figma
        },
        {
            'image': Sketch
        },
        {
            'image': Canva
        },
    ]
    return (
        <div className="w-full">
            <div className="hidden sm:block">
                <UiUxWeb service={props.service} benefits={benefits} applications={applications} technologies={technologies} />
            </div>
            <div className="sm:hidden">
                <UiUxMobile service={props.service} benefits={benefits} applications={applications} technologies={technologies} />
            </div>
        </div>
    );
}

export default UiUx;