import React from "react";
import ITConsultancyBanner from "../../assets/it_consulting_banner.png";
import { FloatingButton, WhatsAppWidget } from "../general";
import ContactBanner from "../general/ContactBanner";
import BenefitsMobile from "./BenefitsMobile";
import ServiceTypeMobile from "./ServiceTypeMobile";
import TopBannerMobile from "./TopBannerMobile";

const ITConsultancyMobile = (props) => {
  return (
    <div className="w-full mb-5">
      <TopBannerMobile
        img={ITConsultancyBanner}
        title="Information Technology Consultancy<br /> services"
        caption="Harmonize Your IT Strategy"
      />
      <div className="flex flex-col justify-center items-center mt-8">
        <p className="text-ss text-center text-blue mt-[3vh]">
          Boost your competitive edge through consultancy
        </p>

        <p className="text-sm font-light p-4">
          Embark on a revolutionary IT adventure with Jasiri Labs. We
          collaborate with you to improve your software architecture and create
          a tech-focused digital roadmap—we don't just offer advice. Our
          software experts guarantee a smooth digital transformation by
          streamlining your software portfolio, from strategic planning to
          accurate implementation.
        </p>
        <div className="bg-[#F8F9F9] w-full mt-5">
          <div className="flex justify-center items-center">
            <p className="text-blue text-ss  m-8  text-center">
              Benefits of IT Consultancy services for business
            </p>
          </div>
          <BenefitsMobile benefits={props.benefits} />
        </div>

        <div>
          <div className="flex justify-center items-center mt-5">
            <p className="text-blue text-ss m-5 text-center">
              Types of consultancy services we provide
            </p>
          </div>
          <ServiceTypeMobile applications={props.applications} />
        </div>

        <ContactBanner />
        <WhatsAppWidget />
        <FloatingButton />
      </div>
    </div>
  );
};

export default ITConsultancyMobile;
