import React, { useEffect } from "react";
import Backend from "../assets/backend.svg"
import Web from "../assets/website.svg"
import Mobile from "../assets/mobile.svg"
import Uiux from "../assets/ui_ux.svg"
import ITConsulting from "../assets/consultation.svg"
import ServicesWeb from "../components/web/ServicesWeb";
import ServicesMobile from "../components/mobile/ServicesMobile";


const Services = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const services = [
        {
            "image": <img src={Backend} alt="" className="h-[7vh] w-[7vh]" />,
            "title": "Backend and API Integration",
            "content": "We can help you build, deploy, and manage complex user interfaces in robust and extremely secure environments using cutting-edge backend API development solutions. We create robust, scalable, and secure server-side logic that powers websites and apps behind the scenes "
        },
        {
            "image": <img src={Web} alt="" className="h-[6vh] w-[6vh]" />,
            "title": "Web Application Development",
            "content": "We develop dynamic and responsive web applications optimized for performance and scalability.Whether you're a small startup or a large enterprise, our team of talented developers, designers, and strategists is dedicated to bringing your vision to life."
        },
        {
            "image": <img src={Mobile} alt="" className="h-[6vh] w-[6vh]" />,
            "title": "Mobile Application Development",
            "content": "We have a team of experienced developers who are proficient in the latest technologies and are dedicated to delivering exceptional results for our clients.We develop high performance, secure mobile applications for iOS and Android devices."
        },
        {
            "image": <img src={Uiux} alt="" className="h-[5vh] w-[5vh]" />,
            "title": "User interface and User Experience",
            "content": "Captivate your users with relevant and meaningful experiences through a thoughtful design and make your product more in line with their needs.We create beautiful, user-friendly designs for websites, mobile apps, and other human-machine interfaces.  "
        },
        {
            "image": <img src={ITConsulting} alt="" className="h-[7vh] w-[7vh]" />,
            "title": "IT Consultancy Services",
            "content": "We provide consultancy services at affordable rates.Our strategy is to collaborate closely with our clients to comprehend their particular needs and difficulties before presenting them with customized solutions that produce quantifiable outcomes. "
        },
        
    ]
    return (
        <div className="w-full">
            <div className="hidden sm:block">
                <ServicesWeb services={services}/>
            </div>
            <div className="sm:hidden">
                <ServicesMobile services={services}/>
            </div>
        </div>
    );
}

export default Services;