import React from "react";
import aboutsec from "../../assets/aboutsec.png";

const AboutSec = () => (
  <section
    className={`flex md:py-4 items-center py-2 flex-col h-auto md:h-80 w-full bg-cover bg-center bg-blend-overlay `}
    style={{
      backgroundImage: `url(${aboutsec})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }}
  >
    <div className="w-full flex justify-center items-center p-4 flex-col mb-8">
      <h1 className="mb-4 md:text-xl text-atomed">Jasiri Labs</h1>
      <h1 className="md:text-2xl text-center font-bold title-font bg-gradient-to-r from-blue to-green bg-clip-text text-transparent">
        Crafting Innovative Software Solutions for Your Business Needs
      </h1>
      <div className=" md:text-lg text-sm align-center text-center mt-8">
        <p>
          With over 5 years of experience in the software development industry,
          Jasiri Labs understands the challenges businesses face in today's{" "}
          <b>dynamic digital environment</b>. Businesses require{" "}
          <b>robust solutions</b> to elevate their software assets and ensure
          efficient management of their digital infrastructure. We collaborate
          with our clients to deliver a <b>comprehensive</b> and{" "}
          <b>tailored platform</b> of software solutions, leveraging our
          expertise to empower you to streamline your operations and{" "}
          <b>drive innovation</b>. We eliminate the complexities and overheads
          typically associated with software development, allowing you to focus
          on your core business objectives.
        </p>
      </div>
    </div>
  </section>
);

export default AboutSec;
