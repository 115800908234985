import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import BlogViewMobile from "../components/mobile/BlogViewMobile";
import BlogViewWeb from "../components/web/BlogViewWeb";


const Blogview = () => {
    const location = useLocation();
    const blog = location.state;

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className="w-full">
            <div className="hidden sm:block">
                <BlogViewWeb blog={blog} />
            </div>
            <div className="sm:hidden">
                <BlogViewMobile blog={blog} />
            </div>
        </div>
    );
}

export default Blogview;