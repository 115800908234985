// import React from "react";
import { useEffect } from "react";
import { Business, CTA, CardDeal, Hero, Service } from "../components";
import { FloatingButton, WhatsAppWidget } from "../components/general";
import OurClients from "../components/web/OurClients";
import styles from "./style";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full overflow-hidden">
      <div className={`bg-white ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <Hero />
        </div>
      </div>
      <div className={`bg-white ${styles.paddingX} ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <Business />
          <CardDeal />
          <Service />
          <OurClients />
          <WhatsAppWidget />
          <FloatingButton />
          <CTA />
          {/* <GetStarted /> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
