import React from "react";
import AdminNav from "../components/web/AdminNav";
import PersonIcon from "../assets/user.svg";
import {
    Card,
    Typography,
    List,
    ListItem,
    ListItemPrefix,
    ListItemSuffix,
    Chip,
} from "@material-tailwind/react";
import IonIcon from "@reacticons/ionicons";
const DashboardHome = () => {
    const getUrl = () => {
        const mode = process.env.REACT_APP_MODE_;
        if (mode === 'prod') {
            return process.env.REACT_APP_PROD_FRONTEND;
        } else {
            return process.env.REACT_APP_DEV_FRONTEND;
        }
    }
    const logout = () => {
        localStorage.clear()
        window.location.replace(`${getUrl()}/`);
    }
    return (
        <div>
            <div className="divide-y">
                <AdminNav />

                <div className="flex space-x-10 divide-x">
                    <Card className="h-[calc(100vh-2rem)] w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5">
                        <div className="mb-2 px-4">
                            <Typography variant="h5" color="blue-gray">
                                Sidebar
                            </Typography>
                        </div>
                        <List>
                            <ListItem>
                                <ListItemPrefix>
                                    <IonIcon className="text-2xl text-blue hover:text-green" name='home-outline' />
                                </ListItemPrefix>
                                Home
                            </ListItem>
                            <ListItem>
                                <ListItemPrefix>
                                    <IonIcon className="text-2xl text-blue hover:text-green" name='book-outline' />
                                </ListItemPrefix>
                                Blogs
                            </ListItem>
                            <ListItem>
                                <ListItemPrefix>
                                    <IonIcon className="text-2xl text-blue hover:text-green" name='mail-outline' />
                                </ListItemPrefix>
                                Comments
                                <ListItemSuffix>
                                    <Chip value="14" size="sm" variant="ghost" color="blue-gray" className="rounded-full" />
                                </ListItemSuffix>
                            </ListItem>
                            <ListItem>
                                <ListItemPrefix>
                                    <IonIcon className="text-2xl text-blue hover:text-green" name='people-circle-outline' />
                                </ListItemPrefix>
                                Profile
                            </ListItem>
                            <ListItem>
                                <ListItemPrefix>
                                    <IonIcon className="text-2xl text-blue hover:text-green" name='cog-outline' />
                                </ListItemPrefix>
                                Settings
                            </ListItem>
                            <ListItem onClick={logout}>
                                <ListItemPrefix>
                                    <IonIcon className="text-2xl text-blue hover:text-green" name='log-out-outline' />
                                </ListItemPrefix>
                                Log Out
                            </ListItem>
                        </List>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default DashboardHome;

