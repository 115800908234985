import { features } from "../../constants";
import styles, { layout } from "../../pages/style";
import Button from "../general/Button";
import { Link } from "react-router-dom";

const FeatureCard = ({ icon, title, content, index }) => (
  <div
    className={`flex flex-row p-6 rounded-[20px] ${index !== features.length - 1 ? "mb-6" : "mb-0"
      } cursor-pointer feature-card`}
  >
    <div className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter}`}>
      <img src={icon} alt="star" className="w-[50%] h-[50%] object-contain" />
    </div>
    <div className="flex-1 flex flex-col ml-3">
      <h4 className="font-poppins font-semibold text-black-2 text-[18px] leading-[23.4px] mb-1">
        {title}
      </h4>
      <p className="font-poppins font-normal text-black-2 text-[16px] leading-[24px]">
        {content}
      </p>
    </div>
  </div>
);

const Business = () => (
  <section id="features" className={layout.section}>
    <div className={layout.sectionInfo}>
      <h2 className={styles.heading2}>
        Why choose
        <br className="sm:block hidden" /> us to be your partner?
      </h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-5 text-wrap`}>
        With our software development expertise, we offer unparalleled
        innovation, reliability, and quality. Partner with us to elevate your
        digital presence and achieve your goals efficiently and effectively.
      </p>

      <Link to="contact-us">
        <Button styles={`mt-10`} />
      </Link>
    </div>

    <div className={`${layout.sectionImg} flex-col`}>
      {features.map((feature, index) => (
        <FeatureCard key={feature.id} {...feature} index={index} />
      ))}
    </div>
  </section>
);

export default Business;
