import { Button } from "@material-tailwind/react";
import bill from "../../assets/bill.png";
import styles, { layout } from "../../pages/style";
import { Link } from "react-router-dom";

const CardDeal = () => (
  <section id="product" className={layout.sectionReverse}>
    <div className={layout.sectionImgReverse}>
      <img src={bill} alt="billing" className="w-[100%] h-[100%] relative" />
    </div>

    <div className={layout.sectionInfo}>
      <h2 className={styles.heading2}>
        Elevate Your Business
        <br className="sm:block hidden" /> with Cutting-Edge Digital Solutions
      </h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-5 text-wrap`}>
        Unlock the full potential of your enterprise and embark on a voyage of
        digital excellence with us as your trusted partner. Let's embark on this
        journey together, where the horizon is limitless, and innovation knows
        no bounds.
      </p>

      <div className="flex flex-row flex-wrap sm:mt-10 mt-6">
        <Link to="services">
          <Button
            variant="filled"
            className=" bg-blue w-[128.86px] h-[42.05px] object-contain mr-5 cursor-pointer transition-transform hover:scale-110 duration-500 ease-out justify-center p-1"
          >
            Our Services
          </Button>
        </Link>
        <Link to="portfolio">
          <Button
            variant="filled"
            className="bg-transparent border border-green text-green w-[144.17px] h-[43.08px] object-contain cursor-pointer transition-transform hover:scale-110 duration-500 ease-out"
          >
            Portfolio
          </Button>
        </Link>
      </div>
    </div>
  </section>
);

export default CardDeal;
