import React from "react";

import IonIcon from "@reacticons/ionicons";

const AdminNav = () => {
    
    return (
        <div>
            <div className="flex justify-between items-center p-[1vh]">
                <div className="flex items-center space-x-6">
                    <p className="text-xl p-2 mx-4 font-semibold text-blue">Jasiri Labs</p>
                </div>

                <div className="flex items-center space-x-3 mr-5" >
                    <IonIcon className="text-2xl text-blue hover:text-green" name='person-outline' />
                    <p className="text-xl p-2 mx-4 font-semibold text-blue">Username</p>
                </div>
            </div>
        </div>
    );
}

export default AdminNav;