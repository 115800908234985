import {
  client1, client2, client3, client4, client5, scrn1, scrn2, scrn3, scrn4, scrn5,
  send, shield, star
} from "../assets";


export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Customization",
    content:
      "we specialize in crafting bespoke software solutions tailored to your needs.",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Exceptional Service",
    content:
      "With a focus on quality, reliability, and innovation, we deliver software that exceeds expectations.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Comprehensive",
    content:
      "From concept to deployment, we're your trusted partner in every stage of software development.",
  },
];

export const clients = [
  {
    "image": client1,
    "link": "https://www.crochethubafrica.com/",
    "screenshot": scrn1
  },
  {
    "image": client2,
    "link": "https://www.allthingsdjango.tech/",
    "screenshot": scrn2
  },
  {
    "image": client3,
    "link": "https://vsterz.com/",
    "screenshot": scrn3
  },
  {
    "image": client4,
    "link": "https://idfkenya.org/",
    "screenshot": scrn4
  },
  {
    "image": client5,
    "link": "https://www.honeyourmarks.com/",
    "screenshot": scrn5
  },
]
