import IonIcon from "@reacticons/ionicons";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { useLocation } from "react-router-dom"; // Assuming you use react-router-dom
import { Link } from "react-router-dom";

const Example = () => {
  const location = useLocation();

  return (
    <div className="">
      <FlyoutLink
        to="/services"
        FlyoutContent={ServiceContent}
        isActive={location.pathname === "/services"}
      >
        Services
      </FlyoutLink>
    </div>
  );
};

const FlyoutLink = ({ children, to, FlyoutContent, isActive }) => {
  const [open, setOpen] = useState(false);
  const showFlyout = FlyoutContent && open;

  return (
    <div
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className="relative w-fit h-fit"
    >
      <Link
        to={to}
        className={`relative ${
          isActive ? "text-blue" : "text-black"
        } hover:text-blue`}
      >
        {children}
        <span
          style={{
            transform: showFlyout ? "scaleX(1)" : "scaleX(0)",
          }}
          className="absolute -bottom-1 w-2/3 -left-0.5 -right-1 h-0.5 rounded-full origin-left scale-x-0 bg-blue transition-transform duration-300 ease-out"
        />
      </Link>
      <AnimatePresence>
        {showFlyout && (
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            style={{ translateX: "-50%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="absolute left-1/2 top-12 bg-blue text-black"
          >
            <div className="absolute -top-6 left-0 right-0 h-6 bg-transparent" />
            <div className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-white" />
            <FlyoutContent />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const ServiceContent = () => {
  return (
    <div className="w-72 bg-white p-6 shadow-xl">
      <div className="mb-3 space-y-3">
        <Link
          to="/services/backend-and-api-integration"
          className="flex justify-between text-sm"
        >
          <div className="flex justify-center items-center space-x-2">
            <IonIcon
              className="text-2xl text-blue hover:text-green"
              name="terminal"
            />
            <div className="text-bold hover:text-green">
              Backend & API Development
            </div>
          </div>
        </Link>
        <Link
          to="/services/user-interface-and-user-experience"
          className="flex justify-between text-sm"
        >
          <div className="flex justify-center items-center space-x-2">
            <IonIcon
              className="text-2xl text-blue hover:text-green"
              name="desktop"
            />
            <div className="text-bold hover:text-green">
              User Interface and UX Design
            </div>
          </div>
        </Link>
        <Link
          to="/services/web-application-development"
          className="flex justify-between text-sm"
        >
          <div className="flex justify-center items-center space-x-2">
            <IonIcon
              className="text-2xl text-blue hover:text-green"
              name="code-slash"
            />
            <div className="text-bold hover:text-green">
              Web App Development
            </div>
          </div>
        </Link>
        <Link
          to="/services/mobile-application-development"
          className="flex justify-between text-sm text-bold"
        >
          <div className="flex justify-center items-center space-x-2">
            <IonIcon
              className="text-2xl text-blue hover:text-green"
              name="phone-portrait"
            />
            <div className="text-bold hover:text-green">
              Mobile App Development
            </div>
          </div>
        </Link>
        <Link
          to="/services/it-consultancy-services"
          className="flex justify-between text-sm text-bold"
        >
          <div className="flex justify-center items-center space-x-2">
            <IonIcon
              className="text-2xl text-blue hover:text-green"
              name="people"
            />
            <div className="text-bold hover:text-green">
              IT Consultancy Services
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Example;
