import React from "react";

const TechnologiesMobile = (props) => {
    return (
        <div className="flex flex-wrap  justify-center  items-center ">
            {props.technologies.map((technology) =>
                <div className="bg-white rounded-2xl m-2">
                    <img src={technology.image} alt="" className=" w-[15vh] h-[15vh] p-8" />
                </div>
            )}
        </div>
    );
}

export default TechnologiesMobile;