import IonIcon from "@reacticons/ionicons";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/logobg2.png";
import Example from "./FlyoutLink";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const menuItems = [
    { text: "Home", to: "/" },
    { text: "About Us", to: "/about-us" },
    { text: "Blog", to: "/blogs" },
    { text: "Portfolio", to: "/portfolio" },
    { text: "Contact Us", to: "/contact-us" },
  ];

  // Mobile servives
  const mobileServices = [{ text: "Services", to: "/services" }];
  // Slice the menuItems array to get items from index 0 to 1
  const displayedItems = menuItems.slice(0, 2);
  const remainingItems = menuItems.slice(2);

  const [currentPage, setCurrentPage] = useState(window.location.pathname); // Get initial pathname

  useEffect(() => {
    const handleLocationChange = () => {
      setCurrentPage(window.location.pathname);
    };

    window.addEventListener("popstate", handleLocationChange); // Listen for popstate event

    return () => window.removeEventListener("popstate", handleLocationChange); // Cleanup
  }, []);

  const location = useLocation();

  useEffect(() => {
    // Close the menu when the route changes
    setOpen(false);
  }, [location]);

  return (
    // bg-white w-full shadow-2xl
    <nav className="w-full z-50">
      <div className="flex items-center font-medium justify-around">
        <div className="z-50 p-2 md:w-auto w-full flex items-center justify-between">
          <Link to="/">
            <img
              src={logo}
              alt="logo"
              className="md:cursor-pointer md:h-20 h-16"
            />
          </Link>
          {/* menu burger */}
          <div
            className="text-3xl md:hidden text-blue"
            onClick={() => setOpen(!open)}
          >
            <IonIcon name={`${open ? "close" : "menu"}`} />
          </div>
        </div>
        <ul className="md:flex text-bold hidden items-center gap-8">
          {displayedItems.map((item, index) => (
            <li key={index}>
              <Link
                to={item.to}
                className={`relative group py-7 px-3 inline-block hover:text-blue ${currentPage === item.to ? "text-blue" : ""
                  }`}
              >
                {item.text}
                <div className="absolute w-1/2 h-0.5 bg-blue rounded-full scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out"></div>
              </Link>
            </li>
          ))}
          <Example />
          {remainingItems.map((item, index) => (
            <li key={index}>
              <Link
                to={item.to}
                className={`relative group py-7 px-3 inline-block hover:text-blue ${currentPage === item.to ? "text-blue" : ""
                  }`}
              >
                {item.text}
                <div className="absolute w-1/2 h-0.5 bg-blue rounded-full  scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out"></div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {/* Mobile Nav */}
      <ul
        className={`md:hidden bg-white absolute w-full h-full bottom-0 py-24 pl-4 duration-500 ${open ? "left-0" : "left-[-100%]"
          }`}
      >
        {displayedItems.map((item, index) => (
          <li key={index}>
            <Link
              to={item.to}
              className={`py-4 px-3 inline-block hover:text-blue ${currentPage === item.to ? "text-blue" : ""
                }`}
            >
              {item.text}
            </Link>
          </li>
        ))}
        {mobileServices.map((item, index) => (
          <li key={index}>
            <Link
              to={item.to}
              className={`py-4 px-3 inline-block hover:text-blue ${currentPage === item.to ? "text-blue" : ""
                }`}
            >
              {item.text}
            </Link>
          </li>
        ))}
        {remainingItems.map((item, index) => (
          <li key={index}>
            <Link
              to={item.to}
              className={`py-4 px-3 inline-block hover:text-blue ${currentPage === item.to ? "text-blue" : ""
                }`}
            >
              {item.text}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
