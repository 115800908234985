import React, { useEffect } from "react";
import Payments from "../assets/payment.png"
import SmartComm from "../assets/bulk_sms.png"
import MapsWeather from "../assets/maps_weather.png"
import NewsFeed from "../assets/news_feed.png"
import FinancialData from "../assets/financial_data.png"
import CustomApi from "../assets/custom_api.png"
import Django from "../assets/django.svg"
import Laravel from "../assets/laravel.svg"
import Go from "../assets/golang.svg"
import Mpesa from "../assets/mpesa.svg"
import Stripe from "../assets/stripe.svg"
import Maps from "../assets/maps.svg"
import Paypal from "../assets/paypal.svg"
import Twilio from "../assets/twilio.svg"
import AfricasTalking from "../assets/aft.png"
import BackendAndApiWeb from "../components/web/BackendAndApiWeb";
import BackendAndApiMobile from "../components/mobile/BackendAndApiMobile";


const BackendAndApi = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const benefits = [
        {
            "title": "Benefits of APIs for business",
            'text': 'Connecting to multiple systems',
            'icon':'contract-outline'
        },
        {
            'text': 'Access to specific data',
            'icon':'analytics-outline'
        },
        {
            'text': 'Automating business processes',
            'icon':'color-wand-outline'
        },
        {
            'text': 'Improve product capabilities',
            'icon':'checkmark-done-outline'
        },
    ]

    const applications = [
        {
            'text': 'Payment APIs',
            'image': Payments
        },
        {
            'text': 'Bulk SMS APIs',
            'image': SmartComm
        },
        {
            'text': 'Maps and weather APIs',
            'image': MapsWeather
        },
        {
            'text': 'News feed APIs',
            'image': NewsFeed
        },
        {
            'text': 'Financial Data APIs',
            'image': FinancialData
        },
        {
            'text': 'Custom APIs',
            'image': CustomApi
        },
    ]

    const technologies = [
        {
            'image': Django
        },
        {
            'image': Laravel
        },
        {
            'image': Go
        },
        {
            'image': Mpesa
        },
        {
            'image': Stripe
        },
        {
            'image': Maps
        },
        {
            'image': Paypal
        },
        {
            'image': Twilio
        },
        {
            'image': AfricasTalking
        },

    ]
    return (
        <div className="w-full">
            <div className="hidden sm:block">
                <BackendAndApiWeb service={props.service} benefits={benefits} applications={applications} technologies={technologies} />
            </div>
            <div className="sm:hidden">
                <BackendAndApiMobile service={props.service} benefits={benefits} applications={applications} technologies={technologies} />
            </div>
        </div>
    );
}

export default BackendAndApi;